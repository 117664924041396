import { SortColumn } from 'react-data-grid';
import { toast } from 'react-toastify';
import {
  AudienceFilterRankingsAction,
  AudienceFiltersRankingsType,
  RankingAction,
  RankingResponse
} from './Audience.programRanking.types';
import getRankings from '../../../../services/Audience/Audience.ranking.service';
import { AppThunk } from '../../..';
import { IAudienceByChannelsComparativeFilterParams } from '../../../../components/Audience/AudiencePanels/OpenTv/Channels/types';

const LoadAudienceFilterRankingsSuccess = (response: RankingResponse) => {
  const payload: AudienceFilterRankingsAction = {
    type: AudienceFiltersRankingsType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterRankingsError = (error: string) => {
  const payload: AudienceFilterRankingsAction = {
    payload: {
      data: {
        count: 0,
        rows: [],
        total: 0
      },
      msg: error
    },
    type: AudienceFiltersRankingsType.ERROR
  };

  return payload;
};

const LoadingAudienceRankingsFilter = () => ({
  type: AudienceFiltersRankingsType.REQUEST,
  payload: {}
});

export const ClearAudienceRankingsData = () => ({
  type: AudienceFiltersRankingsType.CLEAR,
  payload: {}
});

export const LoadAudienceRankingsRequest =
  ({
    target,
    timeRange,
    programmingGenders,
    channels,
    plaza,
    channelsNotIn,
    program,
    period,
    order,
    limit,
    offset
  }: IAudienceByChannelsComparativeFilterParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceRankingsFilter());
      const response = await getRankings({
        target,
        timeRange,
        programmingGenders,
        channels,
        plaza,
        channelsNotIn,
        program,
        period,
        order,
        limit,
        offset
      });

      dispatch(LoadAudienceFilterRankingsSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterRankingsError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os períodos disponíveis'
      );
    }
  };

export const SetRankingPage = (newPage: number): RankingAction => ({
  type: AudienceFiltersRankingsType.PAGE,
  payload: {
    page: newPage
  }
});

export const SetRankingRowsPerPage = (newRows: number): RankingAction => ({
  type: AudienceFiltersRankingsType.ROWS_PER_PAGE,
  payload: {
    rowsPerPage: newRows
  }
});

export const SetRankingSortColumns = (
  columns: SortColumn[]
): RankingAction => ({
  type: AudienceFiltersRankingsType.SORT_COLUMNS,
  payload: {
    sortColumns: columns
  }
});
