export default {
  home: {
    path: '/',
    childrens: {
      audience: {
        path: '/audience',
        childrens: {
          regular_plazas: {
            path: 'regular-plazas',
            link: 'https://app.powerbi.com/reportEmbed?reportId=d5d7c30f-df91-48e5-8199-ffe5c85e71c8&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6',
            tab: 0
          },
          notebook_plazas: {
            path: 'notebook-plazas',
            link: 'https://app.powerbi.com/reportEmbed?reportId=2a6c99a0-9b9c-426c-b243-8ff50d188879&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6',
            tab: 1
          },
          pay_tv: {
            path: 'pay-tv',
            link: 'https://app.powerbi.com/reportEmbed?reportId=5a6fdfe3-a3fe-49a8-abcf-de0bdb32595c&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6',
            tab: 2
          },
          digital_comscore: {
            path: 'digital-comscore',
            link: 'https://app.powerbi.com/reportEmbed?reportId=40e03095-0eaa-4952-84e8-2c77a1685be0&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6',
            tab: 3
          },
          clusters: {
            path: 'clusters',
            link: 'https://app.powerbi.com/reportEmbed?reportId=8fcfb619-5263-4e68-aba1-ead07c35bb3d&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6',
            tab: 4
          },
          help: {
            path: 'help',
            link: 'https://app.powerbi.com/reportEmbed?reportId=61bb7704-7c79-4181-8dc8-cfe62a2309e1&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6',
            tab: 5
          },
          openTv: {
            path: 'open-tv',
            childrens: {
              regularPlazas: {
                path: 'regular-plazas',
                tab: 0,
                childrens: {
                  channels: {
                    tab: 0,
                    path: 'channels',
                    childrens: {
                      comparative: { tab: 0, path: 'comparative' },
                      radiography: { tab: 1, path: 'radiography' }
                    }
                  },
                  programs: {
                    tab: 1,
                    path: 'programs',
                    childrens: {
                      ranking: { tab: 0, path: 'ranking' },
                      radiography: { tab: 1, path: 'radiography' }
                    }
                  }
                }
              },
              notebookPlazas: {
                path: 'notebook-plazas',
                tab: 1,
                childrens: {
                  channels: { tab: 0, path: 'channels' },
                  programs: { tab: 1, path: 'programs' }
                }
              }
            }
          }
        }
      },
      competitive: {
        path: '/competitive',
        childrens: {
          consolidated: { path: 'consolidated', tab: 0 },
          comparative: { path: 'comparative', tab: 1 }
        }
      },
      simulations: '/',
      simulation: {
        path: '/simulation',
        childrens: {
          filters: 'filters',
          ranking: 'ranking',
          planning: 'planning',
          summary: 'summary'
        }
      },
      users: '/users'
    }
  },
  unauthorized: '/unauthorized',
  designSystem: '/design-system'
};
