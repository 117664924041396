import { toast } from 'react-toastify';

import { AppThunk } from '../../../..';
import {
  AudienceFilterProgramsAction,
  AudienceFiltersProgramsType,
  ProgramResponse
} from './Audience.programs.types';
import { IAudienceByChannelsRadiographyRequestParams } from '../../../../../components/Audience/AudiencePanels/OpenTv/Channels/types';
import getPrograms from '../../../../../services/Audience/Audience.programs.service';

const LoadAudienceFilterProgramsSuccess = (response: ProgramResponse) => {
  const payload: AudienceFilterProgramsAction = {
    type: AudienceFiltersProgramsType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterProgramsError = (error: string) => {
  const payload: AudienceFilterProgramsAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersProgramsType.ERROR
  };

  return payload;
};

const LoadingAudienceProgramsFilter = () => ({
  type: AudienceFiltersProgramsType.REQUEST,
  payload: {}
});

export const ClearAudienceProgramsData = () => ({
  type: AudienceFiltersProgramsType.CLEAR,
  payload: {}
});

export const LoadAudienceFilterProgramsRequest =
  ({
    targets,
    timeRanges,
    weekDays,
    programmingGenders,
    channels,
    plazas,
    periods,
    table,
    channelsNotIn
  }: IAudienceByChannelsRadiographyRequestParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceProgramsFilter());
      const response = await getPrograms({
        targets,
        timeRanges: timeRanges ?? ['Total dia (06h-30H)'],
        weekDays: weekDays ?? ['TODOS'],
        programmingGenders,
        channels,
        plazas,
        periods,
        table: table ?? 'anual',
        fields: ['nm_programa'],
        channelsNotIn
      });

      dispatch(LoadAudienceFilterProgramsSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterProgramsError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os dados de Programas'
      );
    }
  };
