import { toast } from 'react-toastify';
import {
  AudiencePercentageAction,
  AudiencePercentageType,
  PercentageResponse
} from './Audience.percentage.types';
import getPercentage from '../../../../../services/Audience/Audience.percentage.service';
import { AppThunk } from '../../../..';
import { IAudienceByChannelsComparativeFilterParams } from '../../../../../components/Audience/AudiencePanels/OpenTv/Channels/types';

const LoadAudiencePercentageSuccess = (response: PercentageResponse) => {
  const payload: AudiencePercentageAction = {
    type: AudiencePercentageType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudiencePercentageError = (error: string) => {
  const payload: AudiencePercentageAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudiencePercentageType.ERROR
  };

  return payload;
};

const LoadingAudiencePercentage = () => ({
  type: AudiencePercentageType.REQUEST,
  payload: {}
});

export const ClearAudiencePercentageData = () => ({
  type: AudiencePercentageType.CLEAR,
  payload: {}
});

export const LoadAudiencePercentageRequest =
  ({
    target,
    timeRange,
    weekDays,
    programmingGenders,
    channels,
    plaza,
    year
  }: IAudienceByChannelsComparativeFilterParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudiencePercentage());
      const response = await getPercentage({
        target,
        timeRange,
        weekDays,
        programmingGenders,
        channels,
        plaza,
        year
      });

      dispatch(LoadAudiencePercentageSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudiencePercentageError(error instanceof Error ? error.message : '')
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os dados de audiência porcentual'
      );
    }
  };
