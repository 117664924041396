import { isArray } from 'lodash';

import { apiV1 } from '../shared/Api.service';
import { PlazaResponse } from '../../store/modules/AudienceModule/OpenTv/Plazas/Audience.plazas.types';
import { ICommonsFilter } from '../../components/Audience/AudiencePanels/OpenTv/Channels/types';

const path = '/audience/plazas';

export default async function getPlazas({
  target,
  timeRange,
  weekDays,
  programmingGenders
}: ICommonsFilter): Promise<PlazaResponse> {
  const { data } = await apiV1.get<PlazaResponse>(path, {
    params: {
      target: target?.value,
      timeRange: timeRange?.value || 'Total dia (06h-30H)',
      weekDays: weekDays?.value || 'TODOS',
      programmingGenders: isArray(programmingGenders)
        ? programmingGenders?.map(
            (programmingGender) => programmingGender.value
          )
        : programmingGenders?.value
    }
  });
  return data;
}
