import { SortColumn } from 'react-data-grid';
import './AudienceOpenTvRegularPlazasProgramsRankingPanel.scss';
import React, { useCallback, useState } from 'react';
import { Skeleton } from '@mui/material';

import PanelContainer from '../../../../../Competitive/PanelContainer';
import GridPagination from '../../../../../GridPagination';
import {
  attributeMaskOrUnmask,
  classMaskOrUnmask
} from '../../../../../../helpers/Utils';
import Info from '../../../../../../assets/images/icon-info.svg';
import AppSVG from '../../../../../SVG/AppSVG';
import AudienceByProgramsRankingFilter from './AudienceOpenTvRegularPlazasProgramsRankingFilter';
import useAudienceOpenTvRegularPlazasProgramsRankingData from './useAudienceOpenTvRegularPlazasProgramsRankingData';
import { ICommonsFilter } from '../types';
import { OptionsList } from '../../../../../Form/types';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import {
  SetRankingPage,
  SetRankingRowsPerPage,
  SetRankingSortColumns
} from '../../../../../../store/modules/AudienceModule/ProgramRanking/Audience.programRanking.actions';

const AudienceByProgramsRanking: React.FC<ICommonsFilter> = ({
  target,
  timeRange,
  newFilter,
  setNewFilter
}: ICommonsFilter) => {
  const dispatch = useAppDispatch();
  const { ranking } = useAppSelector((state) => state);

  const [plaza, setPlaza] = React.useState<OptionsList | undefined>();
  const [channels, setChannels] = React.useState<OptionsList[] | undefined>();
  const [programmingGenders, setProgrammingGenders] = React.useState<
    OptionsList[] | undefined
  >();
  const [period, setPeriod] = React.useState<OptionsList | undefined>();
  const [limit, setLimit] = React.useState<number | undefined>();
  const [offset, setOffset] = React.useState<number | undefined>();
  const [orderBy, setOrderBy] = useState<string | undefined>(
    ranking.sortColumns.length
      ? ranking.sortColumns[0].columnKey
      : 'audienciaPc'
  );
  const [orderDir, setOrderDir] = useState<string | undefined>(
    ranking.sortColumns.length ? ranking.sortColumns[0].direction : 'DESC'
  );

  useAudienceOpenTvRegularPlazasProgramsRankingData({
    target,
    timeRange,
    plaza,
    channels,
    programmingGenders,
    period,
    order: `${orderBy} ${orderDir}`,
    limit,
    offset,
    setPlaza,
    setChannels,
    setProgrammingGenders,
    setPeriod
  });

  const MessageNoRows = ranking.data?.length === 0 && (
    <div className="pr-5 pl-5 no-message" {...attributeMaskOrUnmask(false)}>
      <div>
        <AppSVG
          height={25}
          loader={<Skeleton height={25} width={24} />}
          src={Info}
          width={24}
        />
      </div>
      <div>Nenhum dado encontrado.</div>
    </div>
  );

  const handleSortColumnsChange = (sortedColumn: SortColumn[]) => {
    setOrderBy(sortedColumn[0]?.columnKey || 'audienciaPc');
    setOrderDir(sortedColumn[0]?.direction || 'DESC');
    dispatch(SetRankingSortColumns(sortedColumn.slice(-1)));
  };

  const handlePageChange = useCallback(
    (newPage: number) => {
      setOffset(newPage * ranking.rowsPerPage);
      dispatch(SetRankingPage(newPage));
    },
    [dispatch, ranking.rowsPerPage, setOffset]
  );

  const handleRowsPerPageChange = useCallback(
    (newRows: number) => {
      setLimit(newRows);
      setOffset(ranking.page * newRows);
      dispatch(SetRankingRowsPerPage(newRows));
    },
    [dispatch, ranking.page]
  );

  return (
    <PanelContainer className="panel-container__flex panel-container__border-all">
      <AudienceByProgramsRankingFilter
        {...{
          plaza,
          channels,
          programmingGenders,
          period,
          setChannels,
          setPeriod,
          setPlaza,
          setProgrammingGenders,
          target,
          newFilter,
          setNewFilter,
          timeRange
        }}
      />
      <GridPagination
        className="ranking ranking__grid--width ranking__grid--height"
        columns={[
          {
            key: 'emissora',
            name: 'Emissora',
            sortable: true,
            resizable: true,
            cellClass: `${classMaskOrUnmask(true)} grid-col__separate--none`,
            headerCellClass: `${classMaskOrUnmask(
              false
            )} grid-col__separate--none`,
            width: 170
          },
          {
            key: 'periodo',
            name: 'Período',
            sortable: true,
            resizable: true,
            cellClass: `${classMaskOrUnmask(true)} grid-col__separate--none`,
            headerCellClass: `${classMaskOrUnmask(
              false
            )} grid-col__separate--none`,
            width: 150
          },
          {
            key: 'programa',
            name: 'Nome do Programa',
            sortable: true,
            resizable: true,
            cellClass: `${classMaskOrUnmask(true)} grid-col__separate--none`,
            headerCellClass: `${classMaskOrUnmask(
              false
            )} grid-col__separate--none`,
            width: 270
          },
          {
            key: 'genero',
            name: 'Gênero',
            sortable: true,
            resizable: true,
            cellClass: `${classMaskOrUnmask(true)} grid-col__separate--none`,
            headerCellClass: `${classMaskOrUnmask(
              false
            )} grid-col__separate--none`,
            width: 200
          },
          {
            key: 'audienciaPc',
            name: 'Audiência %',
            sortable: true,
            resizable: true,
            cellClass: `${classMaskOrUnmask(true)} grid-col__separate--none`,
            headerCellClass: `${classMaskOrUnmask(
              false
            )} grid-col__separate--none`,
            width: 150
          },
          {
            key: 'share',
            name: 'Share %',
            sortable: true,
            resizable: true,
            cellClass: `${classMaskOrUnmask(true)} grid-col__separate--none`,
            headerCellClass: `${classMaskOrUnmask(
              false
            )} grid-col__separate--none`,
            width: 150
          },
          // {
          //   key: 'audienciaAvg',
          //   name: 'Audiência Média P/ Minuto',
          //   sortable: true,
          //   resizable: true,
          //   cellClass: `${classMaskOrUnmask(true)} grid-col__separate--none`,
          //   headerCellClass: `${classMaskOrUnmask(
          //     false
          //   )} grid-col__separate--none`,
          //   width: 230
          // },
          {
            key: 'exibicoes',
            name: 'Exibições',
            sortable: true,
            resizable: true,
            cellClass: `${classMaskOrUnmask(true)} grid-col__separate--none`,
            headerCellClass: `${classMaskOrUnmask(
              false
            )} grid-col__separate--none`,
            width: 100
          }
        ]}
        data-testid="userList"
        defaultColumnOptions={{ resizable: true }}
        hasPagination
        /* loading={users.loading} */
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        /* onSearchChange={handleSearchChange} */
        onSortColumnsChange={handleSortColumnsChange}
        renderers={{ noRowsFallback: MessageNoRows }}
        hasSearch={false}
        rows={ranking.data}
        page={ranking.page}
        rowsPerPage={ranking.rowsPerPage}
        sortColumns={ranking.sortColumns}
        countData={ranking.total}
      />
    </PanelContainer>
  );
};

export default AudienceByProgramsRanking;
