import { toast } from 'react-toastify';
import {
  AudienceFilterPeriodsAction,
  AudienceFiltersPeriodsType,
  PeriodResponse
} from './Audience.periods.types';
import getPeriods from '../../../../../services/Audience/Audience.periods.service';
import { AppThunk } from '../../../..';
import { IAudienceByChannelsComparativeFilterParams } from '../../../../../components/Audience/AudiencePanels/OpenTv/Channels/types';

const LoadAudienceFilterPeriodsSuccess = (response: PeriodResponse) => {
  const payload: AudienceFilterPeriodsAction = {
    type: AudienceFiltersPeriodsType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterPeriodsError = (error: string) => {
  const payload: AudienceFilterPeriodsAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersPeriodsType.ERROR
  };

  return payload;
};

const LoadingAudiencePeriodsFilter = () => ({
  type: AudienceFiltersPeriodsType.REQUEST,
  payload: {}
});

export const ClearAudiencePeriodsData = () => ({
  type: AudienceFiltersPeriodsType.CLEAR,
  payload: {}
});

export const LoadAudienceFilterPeriodsRequest =
  ({
    target,
    timeRange,
    weekDays,
    programmingGenders,
    channels,
    plaza,
    channelsNotIn,
    program
  }: IAudienceByChannelsComparativeFilterParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudiencePeriodsFilter());
      const response = await getPeriods({
        target,
        timeRange,
        weekDays,
        programmingGenders,
        channels,
        plaza,
        channelsNotIn,
        program
      });

      dispatch(LoadAudienceFilterPeriodsSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterPeriodsError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os períodos disponíveis'
      );
    }
  };
