import { toast } from 'react-toastify';

import { AppThunk } from '../../../../..';
import {
  AudienceFilterAvarageDatasAction,
  AudienceFiltersAvarageDatasType,
  AvarageDataResponse
} from './Audience.avarageDatas.types';
import { IAudienceByChannelsRadiographyRequestParams } from '../../../../../../components/Audience/AudiencePanels/OpenTv/Channels/types';
import getRadiographies from '../../../../../../services/Audience/Audience.radiographies.service';

const LoadAudienceFilterAvarageDatasSuccess = (
  response: AvarageDataResponse
) => {
  const payload: AudienceFilterAvarageDatasAction = {
    type: AudienceFiltersAvarageDatasType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterAvarageDatasError = (error: string) => {
  const payload: AudienceFilterAvarageDatasAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersAvarageDatasType.ERROR
  };

  return payload;
};

const LoadingAudienceAvarageDatasFilter = () => ({
  type: AudienceFiltersAvarageDatasType.REQUEST,
  payload: {}
});

export const ClearAudienceAvarageDatasData = () => ({
  type: AudienceFiltersAvarageDatasType.CLEAR,
  payload: {}
});

export const LoadAudienceFilterAvarageDatasByChannelsRequest =
  ({
    targets,
    timeRanges,
    weekDays,
    programmingGenders,
    channels,
    plazas,
    periods,
    table
  }: IAudienceByChannelsRadiographyRequestParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceAvarageDatasFilter());
      const response = await getRadiographies({
        targets,
        timeRanges,
        weekDays,
        programmingGenders,
        channels,
        plazas,
        periods,
        table: table ?? 'anual',
        fields: [
          'pc_audiencia',
          'pc_share_tle',
          'vl_audiencia_abs',
          'vl_alcance_abs',
          'hr_ats'
        ]
      });

      dispatch(LoadAudienceFilterAvarageDatasSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterAvarageDatasError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os dados de audiência'
      );
    }
  };
