import { isArray } from 'lodash';

import { apiV1 } from '../shared/Api.service';
import { AbsoluteResponse } from '../../store/modules/AudienceModule/FreeTVRegularConc/GraphsAbsolutes/Audience.absolutes.types';
import { IAudienceByChannelsComparativeFilterParams } from '../../components/Audience/AudiencePanels/OpenTv/Channels/types';

const path = '/audience/graphsConc/getAbsolutes';

export default async function getAbsolutes({
  target,
  timeRange,
  weekDays,
  programmingGenders,
  channels,
  plaza,
  year
}: IAudienceByChannelsComparativeFilterParams): Promise<AbsoluteResponse> {
  const { data } = await apiV1.get<AbsoluteResponse>(path, {
    params: {
      target: target?.value,
      timeRange: timeRange?.value,
      weekDays: weekDays?.value,
      programmingGenders: isArray(programmingGenders)
        ? programmingGenders?.map(
            (programmingGender) => programmingGender.value
          )
        : programmingGenders?.value,
      plaza: plaza?.value,
      channels: channels?.map((channel) => channel.value),
      year: year?.value
    }
  });
  return data;
}
