/* eslint-disable @typescript-eslint/no-unused-vars */
import './AudienceOpenTvRegularPlazasChannelsFilters.scss';
import React, { useRef, useEffect, useMemo } from 'react';
import { FormHandles } from '@unform/core';
import { checkboxClasses, Grid, Typography } from '@mui/material';

import ButtonApp from '../../../../ButtonApp';
import FormContainer from '../../../../Form/FormContainer';
import { OptionsList } from '../../../../Form/types';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import LoadAudienceFilterTargetsRequest from '../../../../../store/modules/AudienceModule/OpenTv/Targets/Audience.targets.actions';
import AutocompleteInput from '../../../../Form/AutocompleteInput';
import { listboxSigleProps } from '../../../../Competitive/CompetitiveFilter/constants';
import LoadAudienceFilterTimeRangesRequest from '../../../../../store/modules/AudienceModule/OpenTv/TimeRanges/Audience.timeRanges.actions';
import LoadAudienceFilterWeekDaysRequest from '../../../../../store/modules/AudienceModule/OpenTv/WeekDays/Audience.weekDays.actions';
import { IAudiencebyChannelsContainerProps } from './types';
import LoadAudienceFilterPlazasRequest from '../../../../../store/modules/AudienceModule/OpenTv/Plazas/Audience.plazas.actions';
import {
  ClearAudienceProgrammingGendersData,
  LoadAudienceFilterProgrammingGendersRequest
} from '../../../../../store/modules/AudienceModule/OpenTv/ProgrammingGenders/Audience.programmingGenders.actions';
import classesGTM from '../../../../../config/classesGTM';

const AudiencebyChannelsFilter: React.FC<IAudiencebyChannelsContainerProps> = ({
  target,
  setTarget,
  timeRange,
  setTimeRange,
  weekDays,
  setWeekDays,
  programmingGenders,
  setProgrammingGenders,
  newFilter,
  setNewFilter
}) => {
  const formRef = useRef<FormHandles>(null);
  const inputsConfig = {
    target: {
      name: 'target',
      defaultValue: 'Total Domicílios',
      state: target
    },
    timeRange: {
      name: 'timeRange',
      defaultValue: 'Total dia (06h-30H)',
      state: timeRange
    },
    weekDays: {
      name: 'weekDays',
      defaultValue: 'TODOS',
      state: weekDays
    },
    programmingGender: {
      name: 'programmingGender',
      defaultValue: 'TODOS',
      state: programmingGenders
    }
  };
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);
  const [ready, setReady] = React.useState(false);

  useEffect(() => {
    dispatch(LoadAudienceFilterTargetsRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(LoadAudienceFilterTimeRangesRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(LoadAudienceFilterWeekDaysRequest());
  }, [dispatch]);

  useEffect(() => {
    if (
      target &&
      timeRange &&
      weekDays &&
      !audience?.openTv?.programmingGenders.error &&
      !audience?.openTv?.programmingGenders.loading &&
      audience?.openTv?.programmingGenders.count === null
    ) {
      dispatch(
        LoadAudienceFilterProgrammingGendersRequest({
          target,
          timeRange,
          weekDays
        })
      );
    }
  }, [
    dispatch,
    target,
    timeRange,
    weekDays,
    audience?.openTv?.programmingGenders
  ]);

  const targetsOptions = useMemo<OptionsList[]>(() => {
    if (audience?.openTv?.targets.count) {
      return audience?.openTv?.targets.data
        .filter((targetOption) => targetOption.name)
        .map((targetOption) => ({
          label: targetOption.name,
          value: targetOption.name
        }));
    }

    return [];
  }, [audience?.openTv?.targets.data, audience?.openTv?.targets.count]);

  useEffect(() => {
    if (!target) {
      setTarget(
        targetsOptions.find(
          (option) => option.value === inputsConfig.target.defaultValue
        )
      );
    }
  }, [inputsConfig.target, targetsOptions, target, setTarget]);

  const timeRangesOptions = useMemo<OptionsList[]>(() => {
    if (audience?.openTv?.timeRanges.count) {
      const order = [
        'Manhã',
        'Tarde',
        'Noite',
        'Madrugada',
        'Total dia (06h-30H)',
        'Total dia (07h-24H)'
      ];

      return audience?.openTv?.timeRanges.data
        .filter((timeRangeOption) => timeRangeOption.name)
        .map((timeRangeOption) => ({
          label: timeRangeOption.name,
          value: timeRangeOption.name
        }))
        .sort((a, b) => order.indexOf(a.label) - order.indexOf(b.label));
    }

    return [];
  }, [audience?.openTv?.timeRanges.data, audience?.openTv?.timeRanges.count]);

  useEffect(() => {
    if (!timeRange) {
      setTimeRange(
        timeRangesOptions.find(
          (option) => option.value === inputsConfig.timeRange.defaultValue
        )
      );
    }
  }, [inputsConfig.timeRange, timeRangesOptions, timeRange, setTimeRange]);

  const weekDaysOptions = useMemo<OptionsList[]>(() => {
    if (audience?.openTv?.weekDays.count) {
      const order = [
        'Segunda',
        'Terça',
        'Quarta',
        'Quinta',
        'Sexta',
        'Sábado',
        'Domingo'
      ];

      return audience?.openTv?.weekDays.data
        .filter((weekDayOption) => weekDayOption.name)
        .map((weekDayOption) => ({
          label: weekDayOption.name,
          value: weekDayOption.name
        }))
        .sort((a, b) => order.indexOf(a.label) - order.indexOf(b.label));
    }

    return [];
  }, [audience?.openTv?.weekDays.data, audience?.openTv?.weekDays.count]);

  useEffect(() => {
    if (!weekDays) {
      setWeekDays(
        weekDaysOptions.find(
          (option) => option.value === inputsConfig.weekDays.defaultValue
        )
      );
    }
  }, [weekDaysOptions, weekDays, setWeekDays, inputsConfig.weekDays]);

  const programmingGendersOptions = useMemo<OptionsList[]>(() => {
    if (audience?.openTv?.programmingGenders.count) {
      return audience?.openTv?.programmingGenders.data
        .filter((programmingGenderOption) => programmingGenderOption.name)
        .map((programmingGenderOption) => ({
          label: programmingGenderOption.name,
          value: programmingGenderOption.name
        }))
        .sort((a, b) => a?.label?.localeCompare(b?.label));
    }

    return [];
  }, [
    audience?.openTv?.programmingGenders.data,
    audience?.openTv?.programmingGenders.count
  ]);

  useEffect(() => {
    if (!programmingGenders) {
      setProgrammingGenders(
        programmingGendersOptions.find(
          (option) =>
            option.value === inputsConfig.programmingGender.defaultValue
        )
      );
    }
  }, [
    programmingGendersOptions,
    programmingGenders,
    setProgrammingGenders,
    inputsConfig.programmingGender
  ]);

  useEffect(() => {
    if (target && timeRange && weekDays && programmingGenders && !ready) {
      if (newFilter !== undefined && setNewFilter) {
        setNewFilter(true);
      }
      dispatch(
        LoadAudienceFilterPlazasRequest({
          target,
          timeRange,
          weekDays,
          programmingGenders
        })
      );
      setReady(true);
    }
  }, [
    dispatch,
    target,
    timeRange,
    weekDays,
    programmingGenders,
    ready,
    newFilter,
    setNewFilter
  ]);

  const clearInputs = (names: string[]) => {
    names.forEach((name) => {
      formRef.current?.clearField(name);
    });
  };

  const handleTargetChange = (option: OptionsList) => {
    dispatch(ClearAudienceProgrammingGendersData());
    clearInputs([inputsConfig.programmingGender?.name]);

    setTarget(option);
  };

  const handleTimeRangeChange = (option: OptionsList) => {
    dispatch(ClearAudienceProgrammingGendersData());
    clearInputs([inputsConfig.programmingGender?.name]);

    setTimeRange(option);
  };

  const handleWeekDaysChange = (option: OptionsList) => {
    dispatch(ClearAudienceProgrammingGendersData());
    clearInputs([inputsConfig.programmingGender?.name]);

    setWeekDays(option);
  };

  const handleProgrammingGenderChange = (option: OptionsList) => {
    setProgrammingGenders(option);
  };

  const handleSendClick = () => {
    if (newFilter !== undefined && setNewFilter) {
      setNewFilter(true);
    }

    if (target && timeRange && weekDays && programmingGenders) {
      dispatch(
        LoadAudienceFilterPlazasRequest({
          target,
          timeRange,
          weekDays,
          programmingGenders
        })
      );
    }
  };

  return (
    <Grid
      container
      padding={5}
      paddingTop={0}
      paddingLeft={0}
      spacing={0}
      columnGap={1}
      rowGap={1}
    >
      <FormContainer
        formRef={formRef}
        onSubmit={handleSendClick}
        fullWidth
        className="formContainer__gap"
        formProps={{
          className: 'formContainer__fullWidth'
        }}
      >
        <Typography variant="h6">Filtros</Typography>
        <AutocompleteInput
          /* classes={} */
          label="Target"
          name={inputsConfig.target?.name}
          data-testid="target"
          disabled={audience?.openTv?.targets.loading}
          loading={audience?.openTv?.targets.loading}
          ListboxProps={listboxSigleProps}
          options={targetsOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={targetsOptions.find(
            (option) => option.value === inputsConfig.target.defaultValue
          )}
          onChange={handleTargetChange}
        />
        <AutocompleteInput
          /* classes={} */
          label="Faixa Horária"
          name={inputsConfig.timeRange?.name}
          data-testid="timeRange"
          disabled={audience?.openTv?.timeRanges.loading}
          loading={audience?.openTv?.timeRanges.loading}
          ListboxProps={listboxSigleProps}
          options={timeRangesOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={timeRangesOptions.find(
            (option) => option.value === inputsConfig.timeRange.defaultValue
          )}
          onChange={handleTimeRangeChange}
        />
        <AutocompleteInput
          /* classes={} */
          label="Dias da Semana"
          name={inputsConfig.weekDays?.name}
          data-testid="weekDay"
          disabled={audience?.openTv?.weekDays.loading}
          loading={audience?.openTv?.weekDays.loading}
          ListboxProps={listboxSigleProps}
          options={weekDaysOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={weekDaysOptions.find(
            (option) => option.value === inputsConfig.weekDays.defaultValue
          )}
          onChange={handleWeekDaysChange}
        />
        <AutocompleteInput
          /* classes={} */
          label="Gênero de Programação"
          name={inputsConfig.programmingGender?.name}
          data-testid="programmingGender"
          disabled={audience?.openTv?.programmingGenders.loading}
          loading={audience?.openTv?.programmingGenders.loading}
          ListboxProps={listboxSigleProps}
          options={programmingGendersOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={programmingGendersOptions.find(
            (option) =>
              option.value === inputsConfig.programmingGender.defaultValue
          )}
          onChange={handleProgrammingGenderChange}
        />
        {/* direction row */}
        <Grid
          item
          width="100%"
          display="flex"
          justifyContent="space-between"
          marginTop={1}
        >
          <ButtonApp
            classes={{
              button:
                'competitive-filter__buttons competitive-filter__buttons--primary',
              loading: 'competitive-filter__buttons--loading',
              tagManager: classesGTM.audience.filter
            }}
            loading={false}
            onClick={handleSendClick}
            title="Filtrar"
          />
          <ButtonApp
            classes={{
              button:
                'competitive-filter__buttons competitive-filter__buttons--secondary',
              tagManager: classesGTM.audience.clearFilter
            }}
            disabled={false}
            onClick={() => formRef.current?.reset() || setReady(false)}
            title="Limpar Filtros"
          />
        </Grid>
      </FormContainer>
    </Grid>
  );
};

export default AudiencebyChannelsFilter;
