import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { attributeMaskOrUnmask } from '../../../helpers/Utils';
import paths from '../../../routes/paths';
import Breadcrumb from '../../Breadcrumb';
import SubHeaderTabs from '../Tabs/OpenTv/SubHeaderTabs';
import './AudienceSubHeader.scss';
import classesGTM from '../../../config/classesGTM';

interface SubHeaderAudienceProps {
  title: string;
}

const { childrens } =
  paths.home.childrens.audience.childrens.openTv.childrens.regularPlazas;

const AudienceSubHeader: React.FC<SubHeaderAudienceProps> = ({ title }) => {
  const location = useLocation();

  const menuItems = useMemo(
    () => [
      {
        index: 0,
        label: 'Emissoras',
        path: childrens.channels.path,
        className: classesGTM.tabs.audienceOpenTvChannels,
        id: 'audience-open-tv-channels'
      },
      {
        index: 1,
        label: 'Programas',
        path: childrens.programs.path,
        className: classesGTM.tabs.audienceOpenTvPrograms,
        id: 'audience-open-tv-programs'
      }
    ],
    []
  );

  return (
    <div className="flex-sb">
      <div className="title-container">
        <div className="title-version">
          <div className="flex-wrap">
            <h2 className="subheader__title" {...attributeMaskOrUnmask(true)}>
              {title}
            </h2>
          </div>
        </div>
        <Breadcrumb
          links={[
            {
              id: 1,
              title: 'Audiência',
              path: paths.home.childrens.audience.path
            },
            {
              id: 2,
              title,
              path: `${paths.home.childrens.audience.path}/${paths.home.childrens.audience.childrens.openTv.path}/${paths.home.childrens.audience.childrens.openTv.childrens.regularPlazas.path}`,
              dataClarityMask: true
            }
          ]}
        />
      </div>
      <SubHeaderTabs
        items={menuItems}
        activeTab={menuItems.findIndex((item) =>
          location.pathname.includes(item.path)
        )}
      />
    </div>
  );
};

export default AudienceSubHeader;
