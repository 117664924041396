import { toast } from 'react-toastify';
import { ICommonsFilter } from '../../../../../components/Audience/AudiencePanels/OpenTv/Channels/types';
import {
  AudienceFilterPlazasAction,
  AudienceFiltersPlazasType,
  PlazaResponse
} from './Audience.plazas.types';
import getPlazas from '../../../../../services/Audience/Audience.plazas.service';
import { AppThunk } from '../../../..';

const LoadAudienceFilterPlazasSuccess = (response: PlazaResponse) => {
  const payload: AudienceFilterPlazasAction = {
    type: AudienceFiltersPlazasType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterPlazasError = (error: string) => {
  const payload: AudienceFilterPlazasAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersPlazasType.ERROR
  };

  return payload;
};

const LoadingAudiencePlazasFilter = () => ({
  type: AudienceFiltersPlazasType.REQUEST,
  payload: {}
});

const LoadAudienceFilterPlazasRequest =
  ({
    target,
    timeRange,
    weekDays,
    programmingGenders
  }: ICommonsFilter): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudiencePlazasFilter());
      const response = await getPlazas({
        target,
        timeRange,
        weekDays,
        programmingGenders
      });

      dispatch(LoadAudienceFilterPlazasSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterPlazasError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os Mercados disponíveis'
      );
    }
  };

export default LoadAudienceFilterPlazasRequest;
