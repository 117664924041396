import './DonutChart.scss';
import React from 'react';
import { animated } from '@react-spring/web';
import { ResponsivePie, CommonPieProps, PieTooltipProps } from '@nivo/pie';
import { DonutChartData } from './types';
import { formatterNumber } from '../../../../helpers/Formatters';
import MetricCard from '../MetricCard';

type ArcLabelsComponent = CommonPieProps<DonutChartData>['arcLabelsComponent'];
interface Props {
  data: DonutChartData[];
  header: string;
}

const customTooltip =
  (): React.FC<PieTooltipProps<DonutChartData>> =>
  ({ datum }) => {
    const { data } = datum;

    return (
      <div className="collumn-chart__tooltip">
        <div className="collumn-chart__tooltip__title">{data.id}</div>
        <div className="collumn-chart__tooltip__text">{data.label}</div>
      </div>
    );
  };

const customLabel =
  (): ArcLabelsComponent =>
  ({ datum, label, style }) => {
    if (datum.data.percentage < 3.5) return <span />;

    return (
      <animated.g
        style={{ pointerEvents: 'none' }}
        transform={style.transform as any}
      >
        <text
          className="donut-chart__chart-label"
          dominantBaseline="central"
          textAnchor="middle"
        >
          {`${label}%`}
        </text>
      </animated.g>
    );
  };

export default function DonutChart({ data, header }: Props) {
  return (
    <MetricCard
      classes={{ content: 'fill-height d-flex flex-column align-center' }}
      header={header}
      height="420px"
    >
      <div className="donut-chart__container">
        <ResponsivePie
          role="application"
          isInteractive
          activeOuterRadiusOffset={4}
          arcLabel={(e) =>
            formatterNumber(e.data.percentage, { fractionDigits: 0 })
          }
          arcLabelsComponent={customLabel()}
          colors={{ datum: 'data.color' }}
          data={data}
          arcLabelsSkipAngle={7}
          arcLabelsTextColor={{ theme: 'labels.text.fill' }}
          margin={{ top: 50, right: 0, bottom: 80, left: 0 }}
          // eslint-disable-next-line react/no-unstable-nested-components
          tooltip={customTooltip()}
          enableArcLabels
          innerRadius={0.85}
          padAngle={0.7}
          cornerRadius={0}
          borderWidth={1}
          borderColor={{
            from: 'color',
            modifiers: [['brighter', 0.6]]
          }}
          enableArcLinkLabels={false}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor={{ from: 'color', modifiers: [] }}
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor="#000"
          arcLabelsRadiusOffset={2}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              size: 4,
              padding: 1,
              stagger: true
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              rotation: -45,
              lineWidth: 6,
              spacing: 10
            }
          ]}
          legends={[
            {
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 30,
              translateY: 70,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: '#999',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000'
                  }
                }
              ]
            }
          ]}
        />
      </div>
    </MetricCard>
  );
}

DonutChart.defaultProps = {};
