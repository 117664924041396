import { toast } from 'react-toastify';
import {
  AudienceAbsoluteAction,
  AudienceAbsoluteType,
  AbsoluteResponse
} from './Audience.absolutes.types';
import getAbsolute from '../../../../../services/Audience/Audience.absolute.service';
import { AppThunk } from '../../../..';
import { IAudienceByChannelsComparativeFilterParams } from '../../../../../components/Audience/AudiencePanels/OpenTv/Channels/types';

const LoadAudienceAbsoluteSuccess = (response: AbsoluteResponse) => {
  const payload: AudienceAbsoluteAction = {
    type: AudienceAbsoluteType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceAbsoluteError = (error: string) => {
  const payload: AudienceAbsoluteAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceAbsoluteType.ERROR
  };

  return payload;
};

const LoadingAudienceAbsolute = () => ({
  type: AudienceAbsoluteType.REQUEST,
  payload: {}
});

export const ClearAudienceAbsoluteData = () => ({
  type: AudienceAbsoluteType.CLEAR,
  payload: {}
});

export const LoadAudienceAbsoluteRequest =
  ({
    target,
    timeRange,
    weekDays,
    programmingGenders,
    channels,
    plaza,
    year
  }: IAudienceByChannelsComparativeFilterParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceAbsolute());
      const response = await getAbsolute({
        target,
        timeRange,
        weekDays,
        programmingGenders,
        channels,
        plaza,
        year
      });

      dispatch(LoadAudienceAbsoluteSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceAbsoluteError(error instanceof Error ? error.message : '')
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os dados de audiência absoluta'
      );
    }
  };
