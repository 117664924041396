enum PayTvColors {
  'A&E' = '#a0522d',
  'AMC' = '#8b4513',
  'Animal Planet' = '#556b2f',
  'AXN' = '#b22222',
  'Band News' = '#4682b4',
  'Band Sports' = '#dc143c',
  'Cartoon Network' = '#daa520',
  'Cinemax' = '#d2691e',
  'CNN Brasil' = '#2f4f4f',
  'Comedy Central' = '#ffd700',
  'Discovery Channel' = '#000080',
  'Discovery H&H' = '#808000',
  'Discovery ID' = '#696969',
  'Discovery Kids' = '#8b008b',
  'Discovery Turbo' = '#ff4500',
  'Disney Channel' = '#6495ed',
  'E! Entertainment' = '#ff69b4',
  'ESPN' = '#cd5c5c',
  'ESPN 2' = '#2e8b57',
  'ESPN 3' = '#483d8b',
  'ESPN 4' = '#ff6347',
  'Film & Arts' = '#8a2be2',
  'Food Network' = '#d8bfd8',
  'Fox Sports 2' = '#a52a2a',
  'FX' = '#7fff00',
  'GloboNews' = '#00ced1',
  'Gloob' = '#1e90ff',
  'GNT' = '#ffe4b5',
  'H2' = '#5f9ea0',
  'HBO' = '#9400d3',
  'HBO 2' = '#ff00ff',
  'HGTV' = '#32cd32',
  'History' = '#b8860b',
  'Jovem Pan News' = '#b03060',
  'Lifetime' = '#e9967a',
  'Megapix' = '#8b0000',
  'MTV' = '#008b8b',
  'Multishow' = '#ba55d3',
  'National Geographic' = '#ffa500',
  'Nick Jr' = '#00fa9a',
  'Nickelodeon' = '#ff4500',
  'Paramount Network' = '#4682b4',
  'Premiere 2' = '#ffb6c1',
  'Premiere 3' = '#cd853f',
  'Premiere 4' = '#708090',
  'Premiere 5' = '#d4af37',
  'Premiere 6' = '#8f7a4e',
  'Premiere 7' = '#4169e1',
  'Premiere 8' = '#ff1493',
  'Premiere Clubes' = '#bc8f8f',
  'Programadora A&E' = '#2f6a47',
  'Programadora AMC' = '#6b4f28',
  'Programadora Box Brasil' = '#7b68ee',
  'Programadora CNN Brasil' = '#4f9a9e',
  'Programadora Globo' = '#e89a7e',
  'Programadora Grupo Disney' = '#d32f2f',
  'Programadora HBO' = '#004d4d',
  'Programadora Jovem Pan News' = '#9932cc',
  'Programadora Grupo Newco' = '#00ff7f',
  'Programadora Sony' = '#ff8c00'
}

export default PayTvColors;
