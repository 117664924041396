import { toast } from 'react-toastify';

import { AppThunk } from '../../../../..';
import {
  AudienceFilterAvarageAudienceByWeekdaysAction,
  AudienceFiltersAvarageAudienceByWeekdaysType,
  AvarageAudienceByWeekdayResponse
} from './Audience.avarageAudienceByWeekdays.types';
import { IAudienceByChannelsRadiographyRequestParams } from '../../../../../../components/Audience/AudiencePanels/OpenTv/Channels/types';
import getRadiographies from '../../../../../../services/Audience/Audience.radiographies.service';

const LoadAudienceFilterAvarageAudienceByWeekdaysSuccess = (
  response: AvarageAudienceByWeekdayResponse
) => {
  const payload: AudienceFilterAvarageAudienceByWeekdaysAction = {
    type: AudienceFiltersAvarageAudienceByWeekdaysType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterAvarageAudienceByWeekdaysError = (error: string) => {
  const payload: AudienceFilterAvarageAudienceByWeekdaysAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersAvarageAudienceByWeekdaysType.ERROR
  };

  return payload;
};

const LoadingAudienceAvarageAudienceByWeekdaysFilter = () => ({
  type: AudienceFiltersAvarageAudienceByWeekdaysType.REQUEST,
  payload: {}
});

export const ClearAudienceAvarageAudienceByWeekdaysData = () => ({
  type: AudienceFiltersAvarageAudienceByWeekdaysType.CLEAR,
  payload: {}
});

export const LoadAudienceFilterAvarageAudienceByWeekdaysRequest =
  ({
    targets,
    timeRanges,
    weekDays,
    programmingGenders,
    channels,
    plazas,
    periods,
    table
  }: IAudienceByChannelsRadiographyRequestParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceAvarageAudienceByWeekdaysFilter());
      const response = await getRadiographies({
        targets,
        timeRanges,
        weekDays,
        programmingGenders,
        channels,
        plazas,
        periods,
        table: table ?? 'anual',
        fields: ['pc_audiencia', 'nm_dia_semana']
      });

      dispatch(LoadAudienceFilterAvarageAudienceByWeekdaysSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterAvarageAudienceByWeekdaysError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os dados de audiência por dia da semana'
      );
    }
  };
