/* eslint-disable @typescript-eslint/no-unused-vars */
import '../AudienceOpenTvRegularPlazasChannelsFilters.scss';
import React, { useRef, useEffect, useMemo } from 'react';
import { FormHandles } from '@unform/core';
import { checkboxClasses, Grid } from '@mui/material';

import { IAudienceByChannelsComparativeFilterProps } from '../types';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import { OptionsList } from '../../../../../Form/types';
import {
  chipProps,
  listboxProps,
  listboxSigleProps
} from '../../../../../Competitive/CompetitiveFilter/constants';
import FormContainer from '../../../../../Form/FormContainer';
import AutocompleteInput from '../../../../../Form/AutocompleteInput';
import {
  ClearAudienceYearsData,
  LoadAudienceFilterYearsRequest
} from '../../../../../../store/modules/AudienceModule/OpenTv/Years/Audience.years.actions';
import {
  ClearAudienceChannelsData,
  LoadAudienceFilterChannelsRequest
} from '../../../../../../store/modules/AudienceModule/OpenTv/Channels/Audience.channels.actions';

const AudiencebyChannelsComparativeFilter: React.FC<
  IAudienceByChannelsComparativeFilterProps
> = ({
  channels,
  plaza,
  year,
  setChannels,
  setPlaza,
  setYear,
  newFilter,
  setNewFilter,
  target,
  timeRange,
  weekDays,
  programmingGenders
}) => {
  const formRef = useRef<FormHandles>(null);
  const FIXED_VALUE = useMemo(() => ({ label: 'Todas', value: 'all' }), []);
  const inputsConfig = useMemo(
    () => ({
      channels: {
        name: 'channels',
        defaultValue: [FIXED_VALUE]
      },
      plaza: {
        name: 'plaza',
        defaultValue: 'PNT'
      },
      year: {
        name: 'year',
        defaultValue: '2024'
      }
    }),
    [FIXED_VALUE]
  );
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);
  const [inputPlazaKey, setInputPlazaKey] = React.useState(0);

  useEffect(() => {
    if (
      plaza &&
      audience?.openTv?.plazas.count !== 0 &&
      audience?.openTv?.plazas.count !== null &&
      audience?.openTv?.plazas.loading === false &&
      target &&
      timeRange &&
      weekDays &&
      programmingGenders &&
      audience?.openTv?.channels.count === null &&
      !audience?.openTv?.channels.error &&
      !audience?.openTv?.channels.loading
    ) {
      dispatch(
        LoadAudienceFilterChannelsRequest({
          plaza,
          target,
          timeRange,
          weekDays,
          programmingGenders
        })
      );
    }
  }, [
    dispatch,
    plaza,
    target,
    timeRange,
    weekDays,
    programmingGenders,
    audience?.openTv?.channels,
    audience?.openTv?.plazas
  ]);

  useEffect(() => {
    if (
      plaza &&
      channels?.length !== 0 &&
      audience?.openTv?.channels.loading === false &&
      audience?.openTv?.channels.count !== 0 &&
      audience?.openTv?.channels.count !== null &&
      target &&
      timeRange &&
      weekDays &&
      programmingGenders &&
      audience?.openTv?.years.count === null &&
      audience?.openTv?.years.loading === false &&
      !audience?.openTv?.years.error
    ) {
      dispatch(
        LoadAudienceFilterYearsRequest({
          plaza,
          channels,
          target,
          timeRange,
          weekDays,
          programmingGenders
        })
      );
    }
  }, [
    dispatch,
    plaza,
    channels,
    target,
    timeRange,
    weekDays,
    programmingGenders,
    audience?.openTv?.years,
    audience?.openTv?.channels,
    audience?.openTv?.years.count
  ]);

  const clearInputs = (names: string[]) => {
    names.forEach((name) => {
      formRef.current?.clearField(name);
    });
  };

  const handlePlazaChange = (option: OptionsList) => {
    dispatch(ClearAudienceChannelsData());
    clearInputs([inputsConfig.channels?.name, inputsConfig.year?.name]);

    setPlaza(option);
  };

  const handleChannelChange = (option: OptionsList[]) => {
    dispatch(ClearAudienceYearsData());
    clearInputs([inputsConfig.year?.name]);

    setChannels(option);
  };

  const handleYearsChange = (option: OptionsList) => {
    setYear(option);
  };

  const handleSendClick = () => {
    console.log('send click', channels, plaza, year);
  };

  const plazasOptions = useMemo<OptionsList[]>(() => {
    if (audience?.openTv?.plazas.count) {
      const option = audience?.openTv?.plazas.data
        .filter((plazaOption) => plazaOption.name)
        .map((plazaOption) => ({
          label: plazaOption?.name,
          value: plazaOption?.name
        }))
        .sort((a, b) => {
          if (a?.value === 'PNT') {
            return -1;
          }

          if (b?.value === 'PNT') {
            return 1;
          }

          return a?.value?.localeCompare(b?.value);
        });

      setInputPlazaKey((prev) => prev + 1);

      return option;
    }

    setInputPlazaKey((prev) => prev + 1);

    return [];
  }, [audience?.openTv?.plazas.data, audience?.openTv?.plazas.count]);

  useEffect(() => {
    if (!plaza) {
      setPlaza(
        plazasOptions.find(
          (option) => option.value === inputsConfig.plaza.defaultValue
        )
      );

      clearInputs([inputsConfig.channels.name, inputsConfig.year.name]);
    }
  }, [
    inputsConfig.plaza,
    plazasOptions,
    plaza,
    setPlaza,
    inputsConfig.channels.name,
    inputsConfig.year.name
  ]);

  const channelsOptions = useMemo<OptionsList[]>(() => {
    if (audience?.openTv?.channels.count) {
      return audience?.openTv?.channels.data
        .filter((channelOption) => channelOption.name)
        .map((channelOption) => ({
          label: channelOption.name,
          value: channelOption.name
        }));
    }

    return [];
  }, [audience?.openTv?.channels.data, audience?.openTv?.channels.count]);

  useEffect(() => {
    if (!channels || !channels.length || channels.length === 0) {
      setChannels([FIXED_VALUE]);
      clearInputs([inputsConfig.year.name]);
    }
  }, [channelsOptions, channels, FIXED_VALUE, setChannels, inputsConfig.year]);

  const yearsOptions = useMemo<OptionsList[]>(() => {
    if (audience?.openTv?.years.count) {
      return audience?.openTv?.years.data
        .filter((timeRangeOption) => timeRangeOption.name)
        .map((timeRangeOption) => ({
          label: timeRangeOption.name,
          value: timeRangeOption.name
        }));
    }

    return [];
  }, [audience?.openTv?.years.data, audience?.openTv?.years.count]);

  useEffect(() => {
    if (!year && yearsOptions.length) {
      setYear(
        yearsOptions.find(
          (option) => option.value === inputsConfig.year.defaultValue
        )
      );
    }
  }, [inputsConfig.year, yearsOptions, year, setYear]);

  useEffect(() => {
    if (newFilter === true) {
      clearInputs([
        inputsConfig.plaza.name,
        inputsConfig.channels.name,
        inputsConfig.year.name
      ]);

      setPlaza(undefined);
      setChannels(undefined);
      setYear(undefined);

      if (setNewFilter) {
        setNewFilter(false);
      }
    }
  }, [newFilter, setNewFilter, setPlaza, setChannels, setYear, inputsConfig]);

  return (
    <Grid
      container
      padding={5}
      paddingTop={0}
      paddingLeft={0}
      spacing={0}
      columnGap={1}
      rowGap={1}
    >
      <FormContainer
        formRef={formRef}
        onSubmit={handleSendClick}
        fullWidth
        className="formContainer__gap"
        formProps={{
          className: 'formContainer__fullWidth'
        }}
        sx={{
          width: '100%',
          flexDirection: 'row'
        }}
      >
        <AutocompleteInput
          /* classes={} */
          label="Mercado"
          key={inputPlazaKey}
          name={inputsConfig.plaza?.name}
          data-testid="mercado"
          disabled={
            audience?.openTv?.plazas.loading ||
            audience?.openTv?.plazas.count === 0
          }
          loading={audience?.openTv?.plazas.loading}
          ListboxProps={listboxSigleProps}
          options={plazasOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={plazasOptions.find(
            (option) => option.value === inputsConfig.plaza.defaultValue
          )}
          onChange={handlePlazaChange}
          sx={{
            width: '33%'
          }}
        />
        <AutocompleteInput
          checkboxClasses={checkboxClasses}
          ChipProps={chipProps(channels)}
          /* classes={} */
          data-testid="channels"
          disableCloseOnSelect
          disabled={
            audience?.openTv?.channels.loading ||
            audience?.openTv?.channels.count === 0
          }
          disableValidateValInOpts
          filterOptions={(x) => x}
          fixedValue={FIXED_VALUE}
          label="Emissoras"
          limitTags={5}
          maxItems={6}
          ListboxProps={listboxProps}
          loading={audience?.openTv?.channels.loading}
          multiple
          name={inputsConfig.channels?.name}
          onChange={handleChannelChange}
          options={channelsOptions}
          required
          returnObject
          dataClarityMask
          sx={{
            width: '33%'
          }}
        />
        <AutocompleteInput
          /* classes={} */
          label="Ano"
          name={inputsConfig.year?.name}
          data-testid="year"
          disabled={
            audience?.openTv?.years.loading ||
            audience?.openTv?.years.count === 0
          }
          loading={audience?.openTv?.years.loading}
          ListboxProps={listboxSigleProps}
          options={yearsOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={yearsOptions.find(
            (option) => option.value === inputsConfig.year.defaultValue
          )}
          onChange={handleYearsChange}
          sx={{
            width: '20%'
          }}
        />
      </FormContainer>
    </Grid>
  );
};

export default AudiencebyChannelsComparativeFilter;
