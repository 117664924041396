import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import AudiencebyChannels from '../AudiencePanels/OpenTv/Channels/AudienceOpenTvRegularPlazasChannelsPanel';
import AudienceByPrograms from '../AudiencePanels/OpenTv/Programs/AudienceOpenTvRegularPlazasProgramsPanel';

export default function AudienceMetrics() {
  const navigate = useNavigate();
  const params = useParams() as {
    tab: 'channels' | 'programs';
    subtab: 'comparative' | 'radiography' | 'ranking' | 'summary';
  };

  useEffect(() => {
    if (!params.subtab) {
      if (!params.tab) {
        navigate('comparative');
      } else if (params.tab === 'channels') {
        navigate('comparative');
      } else if (params.tab === 'programs') {
        navigate('ranking');
      }
    }
  }, [params.tab, params.subtab, navigate]);

  switch (params.tab) {
    case 'channels':
      return <AudiencebyChannels />;
    case 'programs':
      return <AudienceByPrograms />;
    default:
      return <AudiencebyChannels />;
  }
}
