import { toast } from 'react-toastify';

import { AppThunk } from '../../../../..';
import {
  AudienceFilterAvarageAudienceByGendersAction,
  AudienceFiltersAvarageAudienceByGendersType,
  AvarageAudienceByGenderResponse
} from './Audience.avarageAudienceByGenders.types';
import getRadiographies from '../../../../../../services/Audience/Audience.radiographies.service';
import { IAudienceByProgramsRadiographyRequestParams } from '../../../../../../components/Audience/AudiencePanels/OpenTv/Programs/types';

const LoadAudienceFilterAvarageAudienceByGendersSuccess = (
  response: AvarageAudienceByGenderResponse
) => {
  const payload: AudienceFilterAvarageAudienceByGendersAction = {
    type: AudienceFiltersAvarageAudienceByGendersType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterAvarageAudienceByGendersError = (error: string) => {
  const payload: AudienceFilterAvarageAudienceByGendersAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersAvarageAudienceByGendersType.ERROR
  };

  return payload;
};

const LoadingAudienceAvarageAudienceByGendersFilter = () => ({
  type: AudienceFiltersAvarageAudienceByGendersType.REQUEST,
  payload: {}
});

export const ClearAudienceAvarageAudienceByGendersData = () => ({
  type: AudienceFiltersAvarageAudienceByGendersType.CLEAR,
  payload: {}
});

export const LoadAudienceFilterAvarageAudienceByGendersRequest =
  ({
    targets,
    timeRanges,
    weekDays,
    programmingGenders,
    channels,
    programs,
    channelsNotIn,
    plazas,
    periods,
    table
  }: IAudienceByProgramsRadiographyRequestParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceAvarageAudienceByGendersFilter());
      const response = await getRadiographies({
        targets,
        timeRanges,
        weekDays,
        programmingGenders,
        channels,
        programs,
        channelsNotIn,
        plazas,
        periods,
        table: table ?? 'anual',
        fields: ['pc_adh', 'nm_target']
      });

      dispatch(LoadAudienceFilterAvarageAudienceByGendersSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterAvarageAudienceByGendersError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os dados de audiência por gênero'
      );
    }
  };
