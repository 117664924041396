import { isArray } from 'lodash';

import { apiV1 } from '../shared/Api.service';
import { YearResponse } from '../../store/modules/AudienceModule/OpenTv/Years/Audience.years.types';
import { IAudienceByChannelsComparativeFilterParams } from '../../components/Audience/AudiencePanels/OpenTv/Channels/types';

const path = '/audience/years';

export default async function getYears({
  plaza,
  channels,
  programmingGenders,
  target,
  timeRange,
  weekDays
}: IAudienceByChannelsComparativeFilterParams): Promise<YearResponse> {
  const { data } = await apiV1.get<YearResponse>(path, {
    params: {
      plaza: plaza?.value,
      channels: channels?.map((channel) => channel.value),
      programmingGenders: isArray(programmingGenders)
        ? programmingGenders?.map(
            (programmingGender) => programmingGender.value
          )
        : programmingGenders?.value,
      target: target?.value,
      timeRange: timeRange?.value,
      weekDays: weekDays?.value
    }
  });
  return data;
}
