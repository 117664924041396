import { isArray } from 'lodash';

import { useMemo, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import { IAudienceByChannelsRadiographyFilterProps } from '../types';
import { LoadAudienceFilterAvarageDatasByChannelsRequest } from '../../../../../../store/modules/AudienceModule/OpenTv/ByChannels/AvarageDatas/Audience.avarageDatas.actions';
import { LoadAudienceFilterAvarageAudienceByWeekdaysRequest } from '../../../../../../store/modules/AudienceModule/OpenTv/ByChannels/AvarageAudienceByWeekdays/Audience.avarageAudienceByWeekdays.actions';
import { LoadAudienceFilterAvarageAudienceByGendersRequest } from '../../../../../../store/modules/AudienceModule/OpenTv/ByChannels/AvarageAudienceByGenders/Audience.avarageAudienceByGenders.actions';
import { LoadAudienceFilterAvarageAudienceByAgesRequest } from '../../../../../../store/modules/AudienceModule/OpenTv/ByChannels/AvarageAudienceByAges/Audience.avarageAudienceByAges.actions';
import { LoadAudienceFilterAvarageAudienceByProgrammingGendersRequest } from '../../../../../../store/modules/AudienceModule/OpenTv/ByChannels/AvarageAudienceByProgrammingGenders/Audience.avarageAudienceByProgrammingGenders.actions';
import { LoadAudienceFilterAvarageAudienceByTimeRangesRequest } from '../../../../../../store/modules/AudienceModule/OpenTv/ByChannels/AvarageAudienceByTimeRanges/Audience.avarageAudienceByTimeRanges.actions';
import { LoadAudienceFilterAvarageAudienceByFinancialClassesRequest } from '../../../../../../store/modules/AudienceModule/OpenTv/ByChannels/AvarageAudienceByFinancialClasses/Audience.avarageAudienceByFinancialClasses.actions';
import { OptionsList } from '../../../../../Form/types';

interface Props extends IAudienceByChannelsRadiographyFilterProps {
  setSelectedTarget: React.Dispatch<
    React.SetStateAction<OptionsList | undefined>
  >;
}

const useAudienceOpenTvRegularPlazasChannelsRadiographyData = ({
  channel,
  plaza,
  period,
  target,
  timeRange,
  weekDays,
  programmingGenders,
  setSelectedTarget
}: Props) => {
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);
  const targetRef = useRef(target);
  const timeRangeRef = useRef(timeRange);
  const weekDaysRef = useRef(weekDays);
  const programmingGendersRef = useRef(programmingGenders);

  useEffect(() => {
    targetRef.current = target;
    timeRangeRef.current = timeRange;
    weekDaysRef.current = weekDays;
    programmingGendersRef.current = programmingGenders;
  }, [target, timeRange, weekDays, programmingGenders]);

  useEffect(() => {
    if (
      plaza &&
      channel &&
      period &&
      targetRef?.current &&
      timeRangeRef?.current &&
      weekDaysRef?.current &&
      programmingGendersRef?.current
    ) {
      if (targetRef?.current?.value && setSelectedTarget) {
        setSelectedTarget(targetRef?.current);
      }

      dispatch(
        LoadAudienceFilterAvarageDatasByChannelsRequest({
          targets: [targetRef?.current?.value],
          timeRanges: [timeRangeRef?.current?.value],
          weekDays: [weekDaysRef?.current?.value],
          programmingGenders: isArray(programmingGendersRef?.current)
            ? programmingGendersRef?.current?.map((prog) => prog.value)
            : [programmingGendersRef?.current?.value],
          channels: [channel?.value],
          plazas: [plaza?.value],
          periods: [period?.label],
          table: period?.tp_dado
        })
      );

      dispatch(
        LoadAudienceFilterAvarageAudienceByWeekdaysRequest({
          targets: [targetRef?.current?.value],
          timeRanges: [timeRangeRef?.current?.value],
          weekDays:
            audience?.openTv?.weekDays?.data
              ?.filter((day) => day.name !== 'TODOS')
              ?.map((day) => day.name) ?? [],
          programmingGenders: isArray(programmingGendersRef?.current)
            ? programmingGendersRef?.current?.map((prog) => prog.value)
            : [programmingGendersRef?.current?.value],
          channels: [channel?.value],
          plazas: [plaza?.value],
          periods: [period?.label],
          table: period?.tp_dado
        })
      );

      dispatch(
        LoadAudienceFilterAvarageAudienceByGendersRequest({
          targets:
            audience?.openTv?.targets?.data
              ?.filter(
                (tar) =>
                  tar.name === 'HH ABCDE 04+' || tar.name === 'MM ABCDE 04+'
              )
              ?.map((tar) => tar.name) ?? [],
          timeRanges: [timeRangeRef?.current?.value],
          weekDays: [weekDaysRef?.current?.value],
          programmingGenders: isArray(programmingGendersRef?.current)
            ? programmingGendersRef?.current?.map((prog) => prog.value)
            : [programmingGendersRef?.current?.value],
          channels: [channel?.value],
          plazas: [plaza?.value],
          periods: [period?.label],
          table: period?.tp_dado
        })
      );

      dispatch(
        LoadAudienceFilterAvarageAudienceByAgesRequest({
          targets:
            audience?.openTv?.targets?.data
              ?.filter(
                (tar) =>
                  tar.name === 'AS ABCDE 04-11' ||
                  tar.name === 'AS ABCDE 12-17' ||
                  tar.name === 'AS ABCDE 18-24' ||
                  tar.name === 'AS ABCDE 25-34' ||
                  tar.name === 'AS ABCDE 35-49' ||
                  tar.name === 'AS ABCDE 50-59' ||
                  tar.name === 'AS ABCDE 60+'
              )
              ?.map((tar) => tar.name) ?? [],
          timeRanges: [timeRangeRef?.current?.value],
          weekDays: [weekDaysRef?.current?.value],
          programmingGenders: isArray(programmingGendersRef?.current)
            ? programmingGendersRef?.current?.map((prog) => prog.value)
            : [programmingGendersRef?.current?.value],
          channels: [channel?.value],
          plazas: [plaza?.value],
          periods: [period?.label],
          table: period?.tp_dado
        })
      );

      dispatch(
        LoadAudienceFilterAvarageAudienceByFinancialClassesRequest({
          targets:
            audience?.openTv?.targets?.data
              ?.filter(
                (tar) =>
                  tar.name === 'AS AB 04+' ||
                  tar.name === 'AS C 04+' ||
                  tar.name === 'AS DE 04+'
              )
              ?.map((tar) => tar.name) ?? [],
          timeRanges: [timeRangeRef?.current?.value],
          weekDays: [weekDaysRef?.current?.value],
          programmingGenders: isArray(programmingGendersRef?.current)
            ? programmingGendersRef?.current?.map((prog) => prog.value)
            : [programmingGendersRef?.current?.value],
          channels: [channel?.value],
          plazas: [plaza?.value],
          periods: [period?.label],
          table: period?.tp_dado
        })
      );

      dispatch(
        LoadAudienceFilterAvarageAudienceByTimeRangesRequest({
          targets: [targetRef?.current?.value],
          timeRanges:
            audience?.openTv?.timeRanges?.data?.map((time) => time.name) ?? [],
          weekDays: [weekDaysRef?.current?.value],
          programmingGenders: isArray(programmingGendersRef?.current)
            ? programmingGendersRef?.current?.map((prog) => prog.value)
            : [programmingGendersRef?.current?.value],
          channels: [channel?.value],
          plazas: [plaza?.value],
          periods: [period?.label],
          table: period?.tp_dado
        })
      );

      dispatch(
        LoadAudienceFilterAvarageAudienceByProgrammingGendersRequest({
          targets: [targetRef?.current?.value],
          timeRanges: [timeRangeRef?.current?.value],
          weekDays: [weekDaysRef?.current?.value],
          programmingGenders:
            audience?.openTv?.programmingGenders?.data
              ?.filter((prog) => prog.name !== 'TODOS')
              ?.map((prog) => prog.name) ?? [],
          channels: [channel?.value],
          plazas: [plaza?.value],
          periods: [period?.label],
          table: period?.tp_dado
        })
      );
    }
  }, [
    plaza,
    channel,
    period,
    audience?.openTv?.targets?.data,
    audience?.openTv?.timeRanges?.data,
    audience?.openTv?.weekDays?.data,
    audience?.openTv?.programmingGenders?.data,
    dispatch,
    setSelectedTarget
  ]);

  const audienceAvarageDataByWeekdays = useMemo(() => {
    const order = [
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
      'Domingo'
    ];

    return audience?.openTv?.byChannels?.avarageAudienceByWeekdays?.data
      ?.map((avarage) => ({
        x: avarage?.nm_dia_semana ?? '',
        y: avarage?.pc_audiencia ?? 0
      }))
      .sort((a, b) => order.indexOf(a.x) - order.indexOf(b.x));
  }, [audience?.openTv?.byChannels?.avarageAudienceByWeekdays?.data]);

  const audienceAvarageDataByGenders = useMemo(() => {
    const avarageByGender: {
      [key: string]: {
        id: string;
        label: string;
        color: string;
        value: number;
        percentage: number;
      };
    } = {
      'MM ABCDE 04+': {
        id: 'FEM',
        label: 'Feminino',
        color: '#FDA529',
        value: 0,
        percentage: 0
      },
      'HH ABCDE 04+': {
        id: 'MASC',
        label: 'Masculino',
        color: '#1EC370',
        value: 0,
        percentage: 0
      }
    };

    audience?.openTv?.byChannels?.avarageAudienceByGenders?.data?.forEach(
      (avarage) => {
        avarageByGender[avarage?.nm_target].value += avarage?.pc_adh ?? 0;
        avarageByGender[avarage?.nm_target].percentage += avarage?.pc_adh ?? 0;
      }
    );

    return Object.values(avarageByGender);
  }, [audience?.openTv?.byChannels?.avarageAudienceByGenders?.data]);

  const audienceAvarageDataByAges = useMemo(() => {
    const avarageByAges: {
      [key: string]: {
        id: string;
        name: string;
        label: string;
        color: string;
        value: number;
        percentage: number;
      };
    } = {
      'AS ABCDE 04-11': {
        id: '04-11',
        name: '04-11',
        label: '04-11',
        color: '#5D43FA',
        value: 0,
        percentage: 0
      },
      'AS ABCDE 12-17': {
        id: '12-17',
        name: '12-17',
        label: '12-17',
        color: '#5D43FA',
        value: 0,
        percentage: 0
      },
      'AS ABCDE 18-24': {
        id: '18-24',
        name: '18-24',
        label: '18-24',
        color: '#5D43FA',
        value: 0,
        percentage: 0
      },
      'AS ABCDE 25-34': {
        id: '25-34',
        name: '25-34',
        label: '25-34',
        color: '#5D43FA',
        value: 0,
        percentage: 0
      },
      'AS ABCDE 35-49': {
        id: '35-49',
        name: '35-49',
        label: '35-49',
        color: '#5D43FA',
        value: 0,
        percentage: 0
      },
      'AS ABCDE 50-59': {
        id: '50-59',
        name: '50-59',
        label: '50-59',
        color: '#5D43FA',
        value: 0,
        percentage: 0
      },
      'AS ABCDE 60+': {
        id: '60+',
        name: '60+',
        label: '60+',
        color: '#5D43FA',
        value: 0,
        percentage: 0
      }
    };

    audience?.openTv?.byChannels?.avarageAudienceByAges?.data?.forEach(
      (avarage) => {
        avarageByAges[avarage?.nm_target].value +=
          Number(avarage?.pc_adh?.toFixed(2)) ?? 0;
        avarageByAges[avarage?.nm_target].percentage +=
          Number(avarage?.pc_adh?.toFixed(2)) ?? 0;
      }
    );

    return Object.values(avarageByAges);
  }, [audience?.openTv?.byChannels?.avarageAudienceByAges?.data]);

  const audienceAvarageDataByFinancialClasses = useMemo(() => {
    const avarageByFinancialClasses: {
      [key: string]: {
        id: string;
        name: string;
        label: string;
        color: string;
        value: number;
        percentage: number;
      };
    } = {
      'AS AB 04+': {
        id: 'AB',
        name: 'AB',
        label: 'AB',
        color: '#FDA529',
        value: 0,
        percentage: 0
      },
      'AS C 04+': {
        id: 'C',
        name: 'C',
        label: 'C',
        color: '#FC228C',
        value: 0,
        percentage: 0
      },
      'AS DE 04+': {
        id: 'DE',
        name: 'DE',
        label: 'DE',
        color: '#05A6FF',
        value: 0,
        percentage: 0
      }
    };

    audience?.openTv?.byChannels?.avarageAudienceByFinancialClasses?.data?.forEach(
      (avarage) => {
        avarageByFinancialClasses[avarage?.nm_target].value +=
          Number(avarage?.pc_adh?.toFixed(2)) ?? 0;
        avarageByFinancialClasses[avarage?.nm_target].percentage +=
          Number(avarage?.pc_adh?.toFixed(2)) ?? 0;
      }
    );

    return Object.values(avarageByFinancialClasses);
  }, [audience?.openTv?.byChannels?.avarageAudienceByFinancialClasses?.data]);

  const audienceAvarageDataByTimeRanges = useMemo(() => {
    const order = [
      'Manhã',
      'Tarde',
      'Noite',
      'Madrugada',
      'Total dia (06h-30H)',
      'Total dia (07h-24H)'
    ];
    return audience?.openTv?.byChannels?.avarageAudienceByTimeRanges?.data
      ?.map((avarage) => ({
        name: avarage?.ds_faixa_horaria,
        id: avarage?.ds_faixa_horaria,
        value: Number(avarage?.pc_audiencia?.toFixed(2)) ?? 0,
        color: '#20B9FC'
      }))
      .sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));
  }, [audience?.openTv?.byChannels?.avarageAudienceByTimeRanges?.data]);

  const audienceAvarageDataByProgrammingGenders = useMemo(() => {
    return audience?.openTv?.byChannels?.avarageAudienceByProgrammingGenders?.data
      ?.map((avarage) => ({
        name: avarage?.nm_genero,
        id: avarage?.nm_genero,
        value: Number(avarage?.pc_audiencia?.toFixed(2)) ?? 0,
        color: '#6A52FA'
      }))
      .sort((a, b) => b.value - a.value);
  }, [audience?.openTv?.byChannels?.avarageAudienceByProgrammingGenders?.data]);

  return {
    audienceAvarageDataByWeekdays,
    audienceAvarageDataByGenders,
    audienceAvarageDataByAges,
    audienceAvarageDataByFinancialClasses,
    audienceAvarageDataByTimeRanges,
    audienceAvarageDataByProgrammingGenders
  };
};

export default useAudienceOpenTvRegularPlazasChannelsRadiographyData;
