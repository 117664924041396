export interface ProgrammingGender {
  name: string;
}
export interface AudienceFilterProgrammingGendersState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: ProgrammingGender[];
  readonly count: number | null;
}

export interface ProgrammingGenderResponse {
  data: {
    rows: ProgrammingGender[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersProgrammingGendersType {
  REQUEST = '@audience/openTv/programmingGenders/load',
  SUCESS = '@audience/openTv/programmingGenders/success',
  ERROR = '@audience/openTv/programmingGenders/error',
  CLEAR = '@audience/openTv/programmingGenders/clear'
}

export interface AudienceFilterProgrammingGendersAction {
  type: AudienceFiltersProgrammingGendersType;
  payload: Omit<ProgrammingGenderResponse, 'status'>;
}
