import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Stack } from '@mui/material';

import PanelContainer from '../../../../../Competitive/PanelContainer';
import BarChartsCard from '../../../../Charts/ChartsCard/BarChartsCard';
import {
  useAppMediaQuery,
  useAppSelector,
  useAppDispatch
} from '../../../../../../hooks';
import {
  formatterNumber,
  formatterNumberShort
} from '../../../../../../helpers/Formatters';
import LineChart from '../../../../Charts/LineChart/LineChart';
import LineChartAbs from '../../../../Charts/LineChart/LineChartAbs';
import { ICommonsFilter } from '../types';
import AudiencebyChannelsComparativeFilter from './AudienceOpenTvRegularPlazasChannelsComparativeFilter';
import { OptionsList } from '../../../../../Form/types';
import {
  Evolution,
  ByYear
} from '../../../../../../store/modules/AudienceModule/FreeTVRegularConc/GraphsPercentage/Audience.percentage.types';
import { LoadAudiencePercentageRequest } from '../../../../../../store/modules/AudienceModule/FreeTVRegularConc/GraphsPercentage/Audience.percentage.actions';
import { LoadAudienceAbsoluteRequest } from '../../../../../../store/modules/AudienceModule/FreeTVRegularConc/GraphsAbsolutes/Audience.absolutes.actions';
import Loading from '../../../../../Loading';
import KantarDescription from '../../../../KantarDescription/KantarDescription';
import getDefaultColor from '../../../../../../helpers/Colors';

const AudienceByChannelsComparative: React.FC<ICommonsFilter> = ({
  target,
  timeRange,
  weekDays,
  programmingGenders,
  newFilter,
  setNewFilter
}) => {
  const matches = useAppMediaQuery('lg');
  const direction = useMemo(() => (!matches ? 'column' : 'row'), [matches]);

  const { audience } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const [plaza, setPlaza] = React.useState<OptionsList | undefined>();
  const [channels, setChannels] = React.useState<OptionsList[] | undefined>();
  const [year, setYear] = React.useState<OptionsList | undefined>();

  const [evolutionData, setEvolutionData] = React.useState<any>();
  const [byYearData, setByYearData] = React.useState<any>();
  const [shareData, setShareData] = React.useState<any>();

  const [byMonthData, setByMonthData] = React.useState<any>();
  const [periodData, setPeriodData] = React.useState<any>();

  const targetRef = useRef(target);
  const timeRangeRef = useRef(timeRange);
  const weekDaysRef = useRef(weekDays);
  const programmingGendersRef = useRef(programmingGenders);

  const evolutionFormater = useCallback((evolution: Evolution[]) => {
    const ids = [...new Set(evolution.map((e) => e.label))];
    return ids.map((id) => {
      return {
        id: id?.length > 8 ? `${id?.substring(0, 8)}...` : id,
        color: getDefaultColor(id, 'opentv'),
        data: evolution
          .filter((e) => e.label === id)
          .map((e) => {
            return {
              x: e.labelMes,
              y: e.value.toFixed(1)
            };
          })
      };
    });
  }, []);

  const byYearFormatter = useCallback((byYear: ByYear[]) => {
    return [...byYear]
      ?.sort((a, b) => b.value - a.value)
      ?.map((e) => {
        return {
          name: e?.label ?? '',
          id: e?.label ?? '',
          color: getDefaultColor(e?.label, 'opentv'),
          value: e?.value?.toFixed(1) ?? 0
        };
      });
  }, []);

  useEffect(() => {
    targetRef.current = target;
    timeRangeRef.current = timeRange;
    weekDaysRef.current = weekDays;
    programmingGendersRef.current = programmingGenders;
  }, [target, timeRange, weekDays, programmingGenders]);

  useEffect(() => {
    if (
      plaza &&
      channels?.length !== 0 &&
      year &&
      targetRef?.current &&
      timeRangeRef?.current &&
      weekDaysRef?.current &&
      programmingGendersRef?.current
    ) {
      const request = {
        target: targetRef.current,
        timeRange: timeRangeRef.current,
        weekDays: weekDaysRef.current,
        programmingGenders: programmingGendersRef.current,
        plaza,
        channels,
        year
      };
      dispatch(LoadAudiencePercentageRequest(request));
      dispatch(LoadAudienceAbsoluteRequest(request));
    }
  }, [year, channels, plaza, dispatch]);

  useEffect(() => {
    const percentages = audience?.openTv?.percentages?.data?.[0] || null;
    if (
      percentages &&
      audience?.openTv?.percentages.count &&
      !audience?.openTv?.percentages.loading &&
      audience?.openTv?.percentages?.data?.length
    ) {
      setEvolutionData(evolutionFormater(percentages?.evolution));
      setByYearData(byYearFormatter(percentages?.byYear ?? []));
      setShareData(byYearFormatter(percentages?.share));
    }
  }, [
    audience?.openTv?.percentages.count,
    audience?.openTv?.percentages.data,
    audience?.openTv?.percentages.loading,
    evolutionFormater,
    byYearFormatter
  ]);

  useEffect(() => {
    const absolutes = audience?.openTv?.absolutes?.data?.[0] || null;
    if (
      absolutes &&
      audience?.openTv?.absolutes.count &&
      !audience?.openTv?.absolutes.loading &&
      audience?.openTv?.absolutes?.data?.length
    ) {
      setByMonthData(evolutionFormater(absolutes.byMonth));
      setPeriodData(byYearFormatter(absolutes.period));
    }
  }, [
    audience?.openTv?.absolutes.count,
    audience?.openTv?.absolutes.data,
    audience?.openTv?.absolutes.loading,
    evolutionFormater,
    byYearFormatter
  ]);

  if (
    (audience?.openTv?.percentages.loading &&
      audience?.openTv?.absolutes.loading) ||
    (!audience?.openTv?.percentages?.data?.length &&
      !audience?.openTv?.absolutes?.data?.length &&
      !audience?.openTv?.percentages?.error &&
      !audience?.openTv?.absolutes?.error)
  ) {
    return (
      <PanelContainer className="panel-container__flex panel-container__border-all panel-container__margin-bottom">
        <AudiencebyChannelsComparativeFilter
          {...{
            channels,
            plaza,
            year,
            setChannels,
            setPlaza,
            setYear,
            target,
            timeRange,
            weekDays,
            programmingGenders,
            newFilter,
            setNewFilter
          }}
        />
        <Loading
          classes={{
            container:
              'loading-container__relative loading-container__min-height'
          }}
        />
      </PanelContainer>
    );
  }

  return (
    <PanelContainer className="panel-container__flex panel-container__border-all panel-container__margin-bottom">
      <AudiencebyChannelsComparativeFilter
        {...{
          channels,
          plaza,
          year,
          setChannels,
          setPlaza,
          setYear,
          target,
          timeRange,
          weekDays,
          programmingGenders,
          newFilter,
          setNewFilter
        }}
      />
      <div>
        {evolutionData ? (
          <LineChart header={`Evolução da audiência `} data={evolutionData} />
        ) : null}
      </div>
      <>
        {byYearData && shareData ? (
          <Stack
            alignItems="center"
            columnGap={2}
            rowGap={4}
            direction={direction}
            spacing={1}
            flexWrap="nowrap"
          >
            <BarChartsCard
              dataComplement="% de audiência"
              header="Média de audiência no ano"
              type="collumn"
              rawData={byYearData || []}
              formatter={(val) => formatterNumber(val, { fractionDigits: 1 })}
              valueFormat={(val) =>
                `${formatterNumber(val, { fractionDigits: 1 })}%`
              }
            />
            <BarChartsCard
              dataComplement="% de share"
              header="Média de share no ano"
              type="collumn"
              rawData={shareData || []}
              formatter={(val) => formatterNumber(val, { fractionDigits: 1 })}
              valueFormat={(val) =>
                `${formatterNumber(val, { fractionDigits: 1 })}%`
              }
            />
          </Stack>
        ) : null}
      </>
      <div>
        {periodData ? (
          <BarChartsCard
            dataComplement="audiência"
            header="Audiência absoluta no período (média)"
            type="horizontal-bar"
            height="600px"
            rawData={periodData || []}
            formatter={(val) => formatterNumber(val, { fractionDigits: 1 })}
            valueFormat={(val) => formatterNumberShort(val)}
          />
        ) : null}
      </div>
      <div>
        {byMonthData ? (
          <LineChartAbs
            header="Audiência absoluta por mês"
            data={byMonthData}
          />
        ) : null}
      </div>
      <KantarDescription />
    </PanelContainer>
  );
};

export default AudienceByChannelsComparative;
