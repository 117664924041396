import { toast } from 'react-toastify';
import {
  AudienceFilterProgrammingGendersAction,
  AudienceFiltersProgrammingGendersType,
  ProgrammingGenderResponse
} from './Audience.programmingGenders.types';
import getProgrammingGenders from '../../../../../services/Audience/Audience.programmingGenders.service';
import { AppThunk } from '../../../..';
import { ICommonsFilter as ICommonsFilterChannel } from '../../../../../components/Audience/AudiencePanels/OpenTv/Channels/types';
import { IAudienceByProgramsRankingFilterParams as ICommonsFilterPrograms } from '../../../../../components/Audience/AudiencePanels/OpenTv/Programs/types';

const LoadAudienceFilterProgrammingGendersSuccess = (
  response: ProgrammingGenderResponse
) => {
  const payload: AudienceFilterProgrammingGendersAction = {
    type: AudienceFiltersProgrammingGendersType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterProgrammingGendersError = (error: string) => {
  const payload: AudienceFilterProgrammingGendersAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersProgrammingGendersType.ERROR
  };

  return payload;
};

const LoadingAudienceProgrammingGendersFilter = () => ({
  type: AudienceFiltersProgrammingGendersType.REQUEST,
  payload: {}
});

export const ClearAudienceProgrammingGendersData = () => ({
  type: AudienceFiltersProgrammingGendersType.CLEAR,
  payload: {}
});

export const LoadAudienceFilterProgrammingGendersRequest =
  ({
    target,
    timeRange,
    weekDays,
    channels,
    plaza,
    channelsNotIn
  }: ICommonsFilterChannel | ICommonsFilterPrograms): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceProgrammingGendersFilter());
      const response = await getProgrammingGenders({
        target,
        timeRange,
        weekDays,
        channels,
        plaza,
        channelsNotIn
      });

      dispatch(LoadAudienceFilterProgrammingGendersSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterProgrammingGendersError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os gêneros de programação.'
      );
    }
  };
