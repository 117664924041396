import { toast } from 'react-toastify';

import { AppThunk } from '../../../../..';
import {
  AudienceFilterAvarageAudienceByMonthsAction,
  AudienceFiltersAvarageAudienceByMonthsType,
  AvarageAudienceByWeekdayResponse
} from './Audience.avarageAudienceByMonths.types';
import getRadiographies from '../../../../../../services/Audience/Audience.radiographies.service';
import { IAudienceByProgramsRadiographyRequestParams } from '../../../../../../components/Audience/AudiencePanels/OpenTv/Programs/types';

const LoadAudienceFilterAvarageAudienceByMonthsSuccess = (
  response: AvarageAudienceByWeekdayResponse
) => {
  const payload: AudienceFilterAvarageAudienceByMonthsAction = {
    type: AudienceFiltersAvarageAudienceByMonthsType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterAvarageAudienceByMonthsError = (error: string) => {
  const payload: AudienceFilterAvarageAudienceByMonthsAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersAvarageAudienceByMonthsType.ERROR
  };

  return payload;
};

const LoadingAudienceAvarageAudienceByMonthsFilter = () => ({
  type: AudienceFiltersAvarageAudienceByMonthsType.REQUEST,
  payload: {}
});

export const ClearAudienceAvarageAudienceByMonthsData = () => ({
  type: AudienceFiltersAvarageAudienceByMonthsType.CLEAR,
  payload: {}
});

export const LoadAudienceFilterAvarageAudienceByMonthsRequest =
  ({
    targets,
    timeRanges,
    weekDays,
    programmingGenders,
    channels,
    programs,
    channelsNotIn,
    plazas,
    nrAno,
    periods,
    table
  }: IAudienceByProgramsRadiographyRequestParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceAvarageAudienceByMonthsFilter());
      const response = await getRadiographies({
        targets,
        timeRanges,
        weekDays,
        programmingGenders,
        channels,
        programs,
        channelsNotIn,
        plazas,
        nrAno,
        periods,
        table: table ?? 'anual',
        fields: ['pc_audiencia', 'tp_dado', 'ds_periodo', 'dt_periodo']
      });

      dispatch(LoadAudienceFilterAvarageAudienceByMonthsSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterAvarageAudienceByMonthsError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os dados de audiência por mês'
      );
    }
  };
