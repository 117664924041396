import { useNavigate } from 'react-router-dom';

import IconRegularPlazas from '../../../assets/images/audience-open-tv-regular-plazas-icon.svg';
import IconNotebookPlazas from '../../../assets/images/audience-open-tv-notebook-plazas-icon.svg';
import IconPayTv from '../../../assets/images/audience-pay-tv-icon.svg';
import IconDigitalComscore from '../../../assets/images/audience-digital-comscore-icon.svg';
import IconClusters from '../../../assets/images/audience-open-tv-clusters-icon.svg';
import classesGTM from '../../../config/classesGTM';
import { ItemProps } from './types';
import paths from '../../../routes/paths';

const Items = () => {
  const navigate = useNavigate();

  const itemsProps: ItemProps[] = [
    {
      itemId: 1,
      icon: IconRegularPlazas,
      title: 'TV Aberta | Praças Regulares',
      disabled: false,
      visibled: true,
      path: `${paths.home.childrens.audience.childrens.openTv.path}/${paths.home.childrens.audience.childrens.openTv.childrens.regularPlazas.path}/${paths.home.childrens.audience.childrens.openTv.childrens.regularPlazas.childrens.channels.path}`,
      classes: { tagManager: classesGTM.audience.openTV_Regular },
      onClick: () => {
        navigate(
          `${paths.home.childrens.audience.childrens.openTv.path}/${paths.home.childrens.audience.childrens.openTv.childrens.regularPlazas.path}/${paths.home.childrens.audience.childrens.openTv.childrens.regularPlazas.childrens.channels.path}`
        );
      }
    },
    {
      itemId: 2,
      icon: IconNotebookPlazas,
      title: 'TV Aberta | Praças Caderno',
      disabled: false,
      visibled: true,
      path: paths.home.childrens.audience.childrens.openTv.childrens
        .notebookPlazas.path,
      classes: { tagManager: classesGTM.audience.openTV_Notebook },
      onClick: () => {
        navigate(paths.home.childrens.audience.childrens.notebook_plazas.path);
      }
    },
    {
      itemId: 3,
      icon: IconPayTv,
      title: 'TV por Assinatura',
      disabled: false,
      visibled: true,
      path: paths.home.childrens.audience.childrens.openTv.childrens
        .notebookPlazas.path,
      classes: { tagManager: classesGTM.audience.payTV },
      onClick: () => {
        navigate(paths.home.childrens.audience.childrens.pay_tv.path);
      }
    },
    {
      itemId: 4,
      icon: IconDigitalComscore,
      title: 'Digital Comscore',
      disabled: false,
      visibled: true,
      path: paths.home.childrens.audience.childrens.openTv.childrens
        .notebookPlazas.path,
      classes: { tagManager: classesGTM.audience.digital },
      onClick: () => {
        navigate(paths.home.childrens.audience.childrens.digital_comscore.path);
      }
    },
    {
      itemId: 5,
      icon: IconClusters,
      title: 'TV Aberta | Clusters',
      disabled: false,
      visibled: true,
      path: paths.home.childrens.audience.childrens.openTv.childrens
        .notebookPlazas.path,
      classes: { tagManager: classesGTM.audience.openTV_Clusters },
      onClick: () => {
        navigate(paths.home.childrens.audience.childrens.clusters.path);
      }
    }
  ];
  return itemsProps;
};

export default Items;
