// import { MetricsByChannels } from '../../../../store/modules/Competitive/ComparativeMetrics/Competitive.comparative.types';
import MetricCard from '../MetricCard';
import MetricChart from '../MetricChart';
import CollumnChart from '../CollumnChart/CollumnChart';
import { BarChartData } from '../CollumnChart/types';
import HorizontalBarChart from '../HorizontalBarChart';

// Receber da API
interface MetricsByChannelsMock {
  name: string;
  id: string;
  value: number;
  color?: string;
  target?: string;
}

interface Props {
  rawData: MetricsByChannelsMock[];
  dataComplement: string;
  header: string;
  type: 'collumn' | 'horizontal-bar';
  height?: string;
  padding?: number;
  formatter?: (value: string | number) => string;
  valueFormat?: (value: number) => string;
}

enum ChannelColors {
  'TOTAL LIGADOS ESPECIAL' = '#000000',
  'GLOBO' = '#5D43FA',
  'NI' = '#20B9FC',
  'SBT' = '#1EC370',
  'RECORD' = '#FC343A',
  'TV BAND' = '#F44DFC',
  'REDE TV' = '#FDA529',
  'CHANNELH' = '#1EC370'
}

export default function BarChartsCard({
  rawData,
  dataComplement,
  header,
  type,
  height,
  padding,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formatter = (value: string | number) => value.toString(),
  valueFormat
}: Props) {
  const data: BarChartData[] = rawData.map(
    (d) => {
      return {
        id: d.name,
        label: `${formatter(d.value)} ${dataComplement}`,
        value: d.value,
        color: d?.color ?? ChannelColors[d.name as keyof typeof ChannelColors],
        targetId: d.id
      };
    },
    [rawData, dataComplement, formatter]
  );

  return (
    <MetricCard
      classes={{ content: 'fill-height d-flex flex-column align-center' }}
      header={header}
      height={height}
    >
      <MetricChart
        classes={{ container: 'bar-chart__container' }}
        renderGraph={() =>
          (type === 'collumn' && (
            <CollumnChart
              data={data}
              padding={padding}
              valueFormat={valueFormat}
            />
          )) ||
          (type === 'horizontal-bar' && (
            <HorizontalBarChart
              data={data}
              padding={padding}
              height={height}
              valueFormat={valueFormat}
            />
          ))
        }
      />
    </MetricCard>
  );
}

BarChartsCard.defaultProps = {
  formatter: undefined,
  height: '420px',
  padding: 0.1,
  valueFormat: undefined
};
