import { IAudienceByChannelsRadiographyRequestParams } from '../../components/Audience/AudiencePanels/OpenTv/Channels/types';
import { IAudienceByProgramsRadiographyRequestParams } from '../../components/Audience/AudiencePanels/OpenTv/Programs/types';
import { apiV1 } from '../shared/Api.service';

const path = '/audience/radiographies';

export default async function getRadiographies({
  targets,
  timeRanges,
  weekDays,
  programmingGenders,
  channels,
  programs,
  plazas,
  periods,
  nrAno,
  table,
  fields,
  channelsNotIn
}:
  | IAudienceByProgramsRadiographyRequestParams
  | IAudienceByChannelsRadiographyRequestParams): Promise<any> {
  const { data } = await apiV1.get(path, {
    params: {
      targets,
      timeRanges,
      weekDays,
      programmingGenders,
      channels,
      programs,
      plazas,
      nr_ano: nrAno,
      periods,
      table,
      fields,
      channelsNotIn
    }
  });

  return data;
}
