import { apiV1 } from '../shared/Api.service';
import { WeekDayResponse } from '../../store/modules/AudienceModule/OpenTv/WeekDays/Audience.weekDays.types';

const path = '/audience/week-days';

export default async function getWeekDays(): Promise<WeekDayResponse> {
  const { data } = await apiV1.get<WeekDayResponse>(path);

  return data;
}
