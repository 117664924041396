import { combineReducers } from 'redux';
import auth from './Auth/Auth.reducer';
import agencies from './Agencies/Agencies.reducer';
import users from './Users/Users.reducer';
import targets from './Targets/Targets.reducer';
import channels from './Channels/Channels.reducer';
import clients from './Clients/Clients.reducer';
import commitments from './Commitments/Commitments.reducer';
import markets from './Markets/Markets.reducer';
import competitiveMarkets from './Competitive/Markets/Competitive.markets.reducer';
import competitiveSectors from './Competitive/Sectors/Competitive.sectors.reducer';
import competitiveClients from './Competitive/Clients/Competitive.clients.reducer';
import competitiveTargets from './Competitive/Targets/Competitive.targets.reducer';
import filters from './Filters/Filters.reducer';
import programs from './Programs/Programs.reducer';
import baseAudience from './BaseAudience/BaseAudience.reducer';
import notifications from './Notifications/Notifications.reducer';
import simulation from './Simulation/Simulation.reducer';
import planning from './Planning/Planning.reducer';
import summary from './Summary/Summary.reducer';
import simulations from './Simulations/Simulations.reducer';
import formats from './Formats/Formats.reducer';
import secondaries from './Secondaries/Secondaries.reducer';
import competitiveChannels from './Competitive/Channels/Competitive.channels.reducer';
import competitiveBrands from './Competitive/Brands/Competitive.brands.reducer';
import competitiveCategories from './Competitive/Categories/Competitive.categories.reducer';
import competitiveCommercial from './Competitive/CommercialTypes/Competitive.commercial.reducer';
import consolidatedMetrics from './Competitive/ConsolidatedMetrics/Competitive.consolidated.reducer';
import comparativeMetrics from './Competitive/ComparativeMetrics/Competitive.comparative.reducer';
import competitiveNav from './Competitive/Navigation/Competitive.navigation.reducer';
import audienceNav from './Audience/Navigation/Audience.navigation.reducer';
import audienceTargets from './AudienceModule/OpenTv/Targets/Audience.targets.reducer';
import audienceTimeRanges from './AudienceModule/OpenTv/TimeRanges/Audience.timeRanges.reducer';
import audienceWeekDays from './AudienceModule/OpenTv/WeekDays/Audience.weekDays.reducer';
import audienceProgrammingGenders from './AudienceModule/OpenTv/ProgrammingGenders/Audience.programmingGenders.reducer';
import audiencePlazas from './AudienceModule/OpenTv/Plazas/Audience.plazas.reducer';
import audienceChannels from './AudienceModule/OpenTv/Channels/Audience.channels.reducer';
import audienceYears from './AudienceModule/OpenTv/Years/Audience.years.reducer';
import audiencePeriods from './AudienceModule/OpenTv/Periods/Audience.periods.reducer';
import audiencePercentages from './AudienceModule/FreeTVRegularConc/GraphsPercentage/Audience.percentage.reducer';
import audienceAbsolutes from './AudienceModule/FreeTVRegularConc/GraphsAbsolutes/Audience.absolutes.reducer';
import audienceAvaregeDatasByChannels from './AudienceModule/OpenTv/ByChannels/AvarageDatas/Audience.avarageDatas.reducer';
import audienceAvarageAudienceByWeekdaysByChannels from './AudienceModule/OpenTv/ByChannels/AvarageAudienceByWeekdays/Audience.avarageAudienceByWeekdays.reducer';
import avarageAudienceByGendersByChannels from './AudienceModule/OpenTv/ByChannels/AvarageAudienceByGenders/Audience.avarageAudienceByGenders.reducer';
import avarageAudienceByAgesByChannels from './AudienceModule/OpenTv/ByChannels/AvarageAudienceByAges/Audience.avarageAudienceByAges.reducer';
import avarageAudienceByFinancialClassesByChannels from './AudienceModule/OpenTv/ByChannels/AvarageAudienceByFinancialClasses/Audience.avarageAudienceByFinancialClasses.reducer';
import avarageAudienceByTimeRangesByChannels from './AudienceModule/OpenTv/ByChannels/AvarageAudienceByTimeRanges/Audience.avarageAudienceByTimeRanges.reducer';
import avarageAudienceByProgrammingGendersByChannels from './AudienceModule/OpenTv/ByChannels/AvarageAudienceByProgrammingGenders/Audience.avarageAudienceByProgrammingGenders.reducer';
import audiencePrograms from './AudienceModule/OpenTv/Programs/Audience.programs.reducer';
import audienceAvaregeDatasByPrograms from './AudienceModule/OpenTv/ByPrograms/AvarageDatas/Audience.avarageDatas.reducer';
import avarageAudienceByGendersByPrograms from './AudienceModule/OpenTv/ByPrograms/AvarageAudienceByGenders/Audience.avarageAudienceByGenders.reducer';
import avarageAudienceByAgesByPrograms from './AudienceModule/OpenTv/ByPrograms/AvarageAudienceByAges/Audience.avarageAudienceByAges.reducer';
import avarageAudienceByFinancialClassesByPrograms from './AudienceModule/OpenTv/ByPrograms/AvarageAudienceByFinancialClasses/Audience.avarageAudienceByFinancialClasses.reducer';
import avarageAudienceByMonths from './AudienceModule/OpenTv/ByPrograms/AvarageAudienceByMonths/Audience.avarageAudienceByMonths.reducer';
import audienceRanking from './AudienceModule/ProgramRanking/Audience.programRanking.reducer';

const competitiveReducers = combineReducers({
  markets: competitiveMarkets,
  sectors: competitiveSectors,
  clients: competitiveClients,
  channels: competitiveChannels,
  brands: competitiveBrands,
  categories: competitiveCategories,
  commercialTypes: competitiveCommercial,
  targets: competitiveTargets
});

const openTvByChannelsReducers = combineReducers({
  avarageDatas: audienceAvaregeDatasByChannels,
  avarageAudienceByWeekdays: audienceAvarageAudienceByWeekdaysByChannels,
  avarageAudienceByGenders: avarageAudienceByGendersByChannels,
  avarageAudienceByAges: avarageAudienceByAgesByChannels,
  avarageAudienceByFinancialClasses:
    avarageAudienceByFinancialClassesByChannels,
  avarageAudienceByTimeRanges: avarageAudienceByTimeRangesByChannels,
  avarageAudienceByProgrammingGenders:
    avarageAudienceByProgrammingGendersByChannels
});

const openTvByProgramsReducers = combineReducers({
  avarageDatas: audienceAvaregeDatasByPrograms,
  avarageAudienceByGenders: avarageAudienceByGendersByPrograms,
  avarageAudienceByAges: avarageAudienceByAgesByPrograms,
  avarageAudienceByFinancialClasses:
    avarageAudienceByFinancialClassesByPrograms,
  avarageAudienceByMonths
});

const openTvReducers = combineReducers({
  targets: audienceTargets,
  timeRanges: audienceTimeRanges,
  weekDays: audienceWeekDays,
  programmingGenders: audienceProgrammingGenders,
  plazas: audiencePlazas,
  channels: audienceChannels,
  years: audienceYears,
  periods: audiencePeriods,
  percentages: audiencePercentages,
  absolutes: audienceAbsolutes,
  programs: audiencePrograms,
  byChannels: openTvByChannelsReducers,
  byPrograms: openTvByProgramsReducers,
  ranking: audienceRanking
});

const audienceReducers = combineReducers({
  openTv: openTvReducers
});

export default () =>
  combineReducers({
    agencies,
    auth,
    users,
    targets,
    channels,
    clients,
    commitments,
    markets,
    filters,
    programs,
    baseAudience,
    simulation,
    simulations,
    notifications,
    planning,
    summary,
    formats,
    secondaries,
    competitive: competitiveReducers,
    consolidatedMetrics,
    comparativeMetrics,
    competitiveNav,
    audienceNav,
    audience: audienceReducers,
    ranking: audienceRanking
  });
