// SCSS
import './Menu.scss';
import { useNavigate } from 'react-router-dom';

import itemsMenuNav from './ItemsMenu';
import paths from '../../../routes/paths';
import classesGTM from '../../../config/classesGTM';

const { childrens } = paths.home.childrens.audience;

const AudienceMenu = () => {
  const navigate = useNavigate();
  const itemsArray = itemsMenuNav();

  return (
    <div className="audience-main-menu">
      <div className="audience-main-menu-content">
        <div className="audience-main-menu-title">
          <h1 className="audience-main-menu__title">
            Bem-vindo ao <br />
            <strong className="audience-main-menu__title__strong">
              Painel de Audiência
            </strong>
          </h1>
        </div>
        <div className="audience-main-menu-subtitle">
          <p>Selecione a opção que deseja visualizar</p>
        </div>
        <ul className="audience-main-menu__list">
          {itemsArray.map((item) => {
            return item?.visibled ? (
              <li key={item.itemId} className="audience-main-menu__item">
                <div className="audience-main-menu__icon">
                  <img
                    className="audience-main-menu__icon"
                    src={item.icon}
                    alt="Ícone"
                  />
                </div>
                <p className="audience-main-menu__item__title">{item.title}</p>
                <button
                  className={`audience-main-menu__item__button ${item?.classes?.tagManager}`}
                  type="button"
                  disabled={item.disabled}
                  onClick={item?.onClick ? item.onClick : undefined}
                >
                  Acessar
                </button>
              </li>
            ) : null;
          })}
        </ul>
        <div className="audience-main-menu-footer">
          <button
            className={`audience-main-menu-footer__button ${classesGTM.audience.help}`}
            type="button"
            onClick={() => navigate(childrens.help.path)}
          >
            Material de Apoio
          </button>
        </div>
      </div>
    </div>
  );
};

export default AudienceMenu;
