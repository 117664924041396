import { isArray } from 'lodash';

import { apiV1 } from '../shared/Api.service';
import { ChannelResponse } from '../../store/modules/AudienceModule/OpenTv/Channels/Audience.channels.types';
import { IAudienceByChannelsComparativeFilterParams } from '../../components/Audience/AudiencePanels/OpenTv/Channels/types';

const path = '/audience/channels';

export default async function getChannels({
  target,
  timeRange,
  weekDays,
  programmingGenders,
  plaza,
  channelsNotIn
}: IAudienceByChannelsComparativeFilterParams): Promise<ChannelResponse> {
  const { data } = await apiV1.get<ChannelResponse>(path, {
    params: {
      target: target?.value,
      timeRange: timeRange?.value,
      weekDays: weekDays?.value ?? 'TODOS',
      programmingGenders: isArray(programmingGenders)
        ? programmingGenders?.map(
            (programmingGender) => programmingGender.value
          )
        : programmingGenders?.value,
      plaza: plaza?.value,
      channelsNotIn
    }
  });
  return data;
}
