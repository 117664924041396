import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import AudienceSubHeader from '../../SubHeader/AudienceSubHeader';
import SubHeader from '../../../SubHeader';

const AudienceOpenTvRegularPlazasPanel = () => {
  return (
    <Box height="calc(100% - 72px)" sx={{ flexGrow: 1 }}>
      <SubHeader>
        <AudienceSubHeader title="TV Aberta | Praças Regulares" />
      </SubHeader>
      <Outlet />
    </Box>
  );
};

export default AudienceOpenTvRegularPlazasPanel;
