import CategoryColors from '../types/CategoriesColors.enum';
import GenderColors from '../types/GendersColors.enum';
import OpenTVColors from '../types/OpenTVColors.enum';
import PayTvColors from '../types/PayTVColors.enum';

const getDefaultColor = (
  name: string,
  type: 'opentv' | 'paytv' | 'gender' | 'category'
) => {
  switch (type) {
    case 'opentv':
      return OpenTVColors[name as keyof typeof OpenTVColors] || '#000000';
    case 'paytv':
      return PayTvColors[name as keyof typeof PayTvColors] || '#000000';
    case 'category':
      return CategoryColors[name as keyof typeof CategoryColors] || '#000000';
    case 'gender':
      return GenderColors[name as keyof typeof GenderColors] || '#000000';
    default:
      return '#000000';
  }
};

export default getDefaultColor;
