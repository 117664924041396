import { isArray } from 'lodash';

import { apiV1 } from '../shared/Api.service';
import { RankingResponse } from '../../store/modules/AudienceModule/ProgramRanking/Audience.programRanking.types';
import { IAudienceByChannelsComparativeFilterParams } from '../../components/Audience/AudiencePanels/OpenTv/Channels/types';

const path = '/audience/program/rankings/items';

export default async function getRanking({
  plaza,
  channels,
  programmingGenders,
  target,
  timeRange,
  channelsNotIn,
  program,
  period,
  order,
  limit,
  offset
}: IAudienceByChannelsComparativeFilterParams): Promise<RankingResponse> {
  const { data } = await apiV1.get<RankingResponse>(path, {
    params: {
      plaza: plaza?.value,
      channels: channels?.map((channel) => channel.value),
      programmingGenders: isArray(programmingGenders)
        ? programmingGenders?.map(
            (programmingGender) => programmingGender.value
          )
        : programmingGenders?.value,
      target: target?.value,
      timeRange: timeRange?.value,
      channelsNotIn,
      program: program?.value ?? 'TODOS',
      period: period?.value ?? new Date().getFullYear(),
      order: order ?? 'audienciaPc',
      limit: limit ?? 10,
      offset: offset ?? 0
    }
  });
  return data;
}
